import getTaskName from '../../../global/helpers/getTaskName';
import pomelloService from '../../../global/services/pomello';
import trelloService from '../../../global/services/trello';
import { appModels } from '../../app';
import { GetSnapshotVariable } from '../components/Triggers';

type Marker = appModels.Settings['titleMarker'];
type Task = appModels.Task | null;

const updateTitle = async (task: appModels.Task, marker: string) => {
  const fetchTaskResponse = await pomelloService.fetchTask(task.id);

  if (fetchTaskResponse.success) {
    const { pomodoros } = fetchTaskResponse.data;

    return trelloService.updateCard(task, {
      name: `${pomodoros} ${marker} ${getTaskName(task.name)}`,
    });
  }
};

export default async function(
  action: any,
  getVariable: GetSnapshotVariable,
  meta: any
) {
  const marker: Marker = getVariable('settings.titleMarker');
  const task: Task = getVariable('task');
  const parentTask: Task = getVariable('parentTask');

  const updates = [];

  if (task) {
    updates.push(updateTitle(task, marker));
  }

  if (parentTask) {
    updates.push(updateTitle(parentTask, marker));
  }

  return Promise.all(updates);
}
