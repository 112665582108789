import trelloService from '../../../global/services/trello';
import { appModels } from '../../app';
import { GetSnapshotVariable } from '../components/Triggers';

export default function(
  action: any,
  getVariable: GetSnapshotVariable,
  meta: any
) {
  const task: appModels.Task | null = getVariable('task');

  if (task && !task.idCard) {
    trelloService.updateCard(task, { closed: true });
  }
}
