import React, { SFC } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/types';
import './miniClock.scss';

type MiniClockProps = MiniClockStateProps;

interface MiniClockStateProps {
  time?: string;
}

const mapStateToProps = (state: RootState) => ({
  time: state.timer.humanTime,
});

const MiniClock: SFC<MiniClockProps> = ({ time }) => {
  return time ? (
    <div className="mini-clock">
      <span className="mini-clock__time">{time}</span>
    </div>
  ) : null;
};

export default connect(mapStateToProps)(MiniClock);
