import { throttle } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { $Values } from 'utility-types';
import { appActions, appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import timestamp from '../../global/helpers/timestamp';
import pomelloService from '../../global/services/pomello';
import { RootAction, RootState } from '../../store/types';
import InputCheckbox from './InputCheckbox';
import InputSelect from './InputSelect';
import InputSliders from './InputSliders';
import SettingsPlaceholder from './SettingsPlaceholder';
import settingsSchema from './settingsSchema.json';

type Settings = Setting[];

export type SettingName = keyof appModels.Settings;

export type SettingValue = $Values<appModels.Settings>;

interface Setting {
  heading: string;
  settings: Array<CheckboxSetting | SelectSetting | SlidersSetting>;
}

interface BaseSetting {
  hint?: string;
  label: string;
  name: keyof appModels.Settings;
}

export interface CheckboxSetting extends BaseSetting {
  type: 'checkbox';
}

export interface SelectSetting extends BaseSetting {
  options: Array<{
    value: string;
    text: string;
  }>;
  suffix?: string;
  type: 'select';
}

export interface SlidersSetting extends BaseSetting {
  sliders: Array<{
    value: SettingName;
    label: string;
  }>;
  type: 'sliders';
}

type SettingsPageProps = SettingsPageOwnProps & SettingsPageDispatchProps;

interface SettingsPageOwnProps {
  bootstrapped: appModels.Bootstrapped;
  settings: appModels.Settings;
}

interface SettingsPageDispatchProps {
  setSetting: (payload: { name: SettingName; value: SettingValue }) => void;
}

const mapStateToProps = (state: RootState) => ({
  bootstrapped: state.app.bootstrapped,
  settings: state.app.settings,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setSetting: appActions.setSetting,
    },
    dispatch
  );

export class SettingsPage extends Component<SettingsPageProps> {
  updateSettingsTimestamp = throttle(
    () => this.props.setSetting({ name: 'timestamp', value: timestamp() }),
    250,
    { leading: false }
  );

  componentDidUpdate = (prevProps: SettingsPageProps) => {
    if (
      prevProps.settings.timestamp &&
      this.props.settings.timestamp !== prevProps.settings.timestamp
    ) {
      this.updateSettings();
    }
  };

  handleSettingChange = (name: SettingName, value: any) => {
    this.props.setSetting({ name, value });

    this.updateSettingsTimestamp();
  };

  getSliderSettings = (sliders: SlidersSetting['sliders']) => {
    return sliders.reduce(
      (settings, { value }) => ({
        ...settings,
        [value]: Number(this.props.settings[value as keyof appModels.Settings]),
      }),
      {}
    );
  };

  updateSettings = () => {
    pomelloService.updateSettings(this.props.settings);
  };

  render() {
    const { bootstrapped, settings } = this.props;

    return (
      <>
        <AppBar title="Settings" />
        {bootstrapped ? (
          (settingsSchema as Settings).map(section => (
            <section key={section.heading}>
              <div className="list__heading">{section.heading}</div>
              <ul className="settings">
                {section.settings.map(setting => (
                  <li className="settings__item" key={setting.name}>
                    <div className="settings__label">
                      <label htmlFor={setting.name}>{setting.label}</label>
                      {setting.hint && (
                        <p className="settings__hint">{setting.hint}</p>
                      )}
                    </div>
                    {(() => {
                      switch (setting.type) {
                        case 'checkbox':
                          return (
                            <InputCheckbox
                              setting={setting}
                              updateSetting={this.handleSettingChange}
                              value={settings[setting.name]}
                            />
                          );
                        case 'select':
                          return (
                            <InputSelect
                              setting={setting}
                              updateSetting={this.handleSettingChange}
                              value={settings[setting.name]}
                            />
                          );
                        case 'sliders':
                          return (
                            <InputSliders
                              setting={setting}
                              updateSetting={this.handleSettingChange}
                              values={this.getSliderSettings(setting.sliders)}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </li>
                ))}
              </ul>
            </section>
          ))
        ) : (
          <SettingsPlaceholder />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage);
