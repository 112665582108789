import React, { ChangeEvent, SFC } from 'react';
import { $Values } from 'utility-types';
import { appModels } from '../../features/app';
import { CheckboxSetting, SettingName } from './SettingsPage';

interface InputCheckbox {
  setting: CheckboxSetting;
  updateSetting: (name: SettingName, value: boolean) => void;
  value: $Values<appModels.Settings>;
}

const InputCheckbox: SFC<InputCheckbox> = ({
  setting,
  updateSetting,
  value,
}) => (
  <input
    checked={Boolean(value)}
    id={setting.name}
    name={setting.name}
    onChange={(event: ChangeEvent<HTMLInputElement>) =>
      updateSetting(setting.name, event.currentTarget.checked)
    }
    type="checkbox"
  />
);

export default InputCheckbox;
