import classnames from 'classnames';
import React, { SFC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ReactComponent as BackArrow } from '../../../global/assets/arrow-back.svg';
import { ReactComponent as MenuIcon } from '../../../global/assets/menu.svg';
import { RootAction } from '../../../store/types';
import { appActions } from '../../app';
import './appBar.scss';

export type AppBarProps = AppBarOwnProps & AppBarDispatchProps;

interface AppBarOwnProps {
  readonly returnRoute?: string;
  readonly title?: string;
  readonly transparent?: boolean;
}

interface AppBarDispatchProps {
  readonly showMenu: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators({ showMenu: appActions.showMenu }, dispatch);

export const AppBar: SFC<AppBarProps> = ({
  returnRoute,
  showMenu,
  title,
  transparent = false,
}) => (
  <div
    className={classnames('app-bar', {
      'is-transparent': transparent,
    })}
  >
    {returnRoute ? (
      <Link to={returnRoute} className="app-bar__icon">
        <BackArrow fill="#fff" />
      </Link>
    ) : (
      <button className="app-bar__icon" onClick={showMenu}>
        <MenuIcon fill="#fff" />
      </button>
    )}
    {title && <span className="app-bar__title">{title}</span>}
  </div>
);

export default connect(
  null,
  mapDispatchToProps
)(AppBar);
