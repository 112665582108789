import { PomelloService } from '..';
import { appModels } from '../../../../features/app';

type AuthUserSuccess = {
  success: true;
  data: {
    token: string;
    user: appModels.PomelloUser;
  };
};

type AuthUserFailure = {
  success: false;
  data?: {
    [key: string]: string;
  };
};

export default async function(
  this: PomelloService,
  email: string,
  password: string
): Promise<AuthUserSuccess | AuthUserFailure> {
  try {
    const response = await this.api.post('/authorize', { email, password });

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    const response = { success: false } as AuthUserFailure;

    if (error.response) {
      const { data, status } = error.response;

      if (status === 422 || status === 423) {
        Object.keys(data.errors).forEach(errorMsg => {
          data.errors[errorMsg] = data.errors[errorMsg][0];
        });

        response.data = data.errors;
      }
    }

    return response;
  }
}
