import { ICard } from '@trello-contrib/trello-api-ts/lib';
import { decodeHex, encodeHex } from 'base-62.js';
import { TrelloService } from '..';

type FetchCardSuccess = {
  success: true;
  data: {
    idList: ICard['idList'];
    shortIdList: string;
  };
};

type FetchCardFailure = {
  success: false;
};

export default async function(
  this: TrelloService,
  idCard: string
): Promise<FetchCardSuccess | FetchCardFailure> {
  try {
    const response = await this.api.get(`/cards/${decodeHex(idCard)}`, {
      params: {
        fields: 'idList',
      },
    });

    return {
      success: true,
      data: {
        idList: response.data.idList,
        shortIdList: encodeHex(response.data.idList),
      },
    };
  } catch (error) {
    return { success: false };
  }
}
