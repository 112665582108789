import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { ReactComponent as TrelloTaco } from '../../global/assets/trello-taco.svg';
import storage from '../../global/helpers/storage';
import trelloService from '../../global/services/trello';
import './connectPage.scss';

export interface ConnectPageProps extends RouteComponentProps {}

export interface ConnectPageState {
  readonly isTrelloCallback: boolean;
}

export class ConnectPage extends Component<ConnectPageProps, ConnectPageState> {
  state: ConnectPageState = {
    isTrelloCallback: false,
  };

  componentWillMount() {
    const {
      location: { hash },
    } = this.props;

    if (/#token=([a-z0-9]{64})/.test(hash)) {
      this.setState({ isTrelloCallback: true });

      storage.set('trelloToken', RegExp.$1);
    }
  }

  render() {
    const { isTrelloCallback } = this.state;

    return !isTrelloCallback ? (
      <div className="connect__container">
        <div className="connect__content">
          <TrelloTaco width="175" height="175" />
          <div className="content">
            <p>Give Pomello access to see your Trello cards</p>
            <a href={trelloService.authUrl} className="button">
              Connect to Trello
            </a>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default ConnectPage;
