import { TrelloService } from '..';
import { appModels } from '../../../../features/app';

type UpdateCardSuccess = {
  success: true;
};

type UpdateCardFailure = {
  success: false;
};

export default async function(
  this: TrelloService,
  task: appModels.Task,
  data: {}
): Promise<UpdateCardSuccess | UpdateCardFailure> {
  try {
    const url = !task.idCard
      ? `/cards/${task.id}`
      : `/cards/${task.idCard}/checkItem/${task.id}`;

    await this.api.put(url, data);

    return { success: true };
  } catch (error) {
    return { success: false };
  }
}
