import { PomelloService } from '..';
import { appModels } from '../../../../features/app';

type FetchUserSuccess = {
  success: true;
  data: appModels.PomelloUser;
};

type FetchUserFailure = {
  success: false;
};

export default async function(
  this: PomelloService
): Promise<FetchUserSuccess | FetchUserFailure> {
  try {
    const response = await this.api.get('/users');

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false };
  }
}
