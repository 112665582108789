import { TrelloService } from '..';

type MarkCheckItemCompleteSuccess = {
  success: true;
};

type MarkCheckItemCompleteFailure = {
  success: false;
};

export default async function(
  this: TrelloService,
  idCard: string,
  id: string
): Promise<MarkCheckItemCompleteSuccess | MarkCheckItemCompleteFailure> {
  try {
    await this.api.put(`/cards/${idCard}/checkItem/${id}`, {
      state: 'complete',
    });

    return { success: true };
  } catch (error) {
    return { success: false };
  }
}
