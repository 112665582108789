import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import App, { appActions, appModels } from '../../features/app';
import Overtime from '../../features/overtime';
import Timer from '../../features/timer';
import Toasts, { toastActions, toastModels } from '../../features/toast';
import Triggers from '../../features/triggers';
import storage from '../../global/helpers/storage';
import pomelloService from '../../global/services/pomello';
import trelloService from '../../global/services/trello';
import { RootAction, RootState } from '../../store/types';
import AuthModal from './AuthModal';
import PremiumModal from './PremiumModal';

export interface BasePageProps extends RouteComponentProps {
  readonly appStatus: appModels.Status;
  readonly createToast: (toast: toastModels.Toast) => void;
  readonly hasBootstrapped: boolean;
  readonly pomelloUser?: appModels.PomelloUser;
  readonly removePomelloUser: () => void;
  readonly removeTrelloUser: () => void;
  readonly resetSettings: () => void;
  readonly setAppStatus: (status: appModels.Status) => void;
  readonly showOvertime: boolean;
  readonly storeBoards: (boards: appModels.Boards) => void;
  readonly storeLists: (lists: appModels.Lists) => void;
  readonly storePomelloUser: (user: appModels.PomelloUser) => void;
  readonly storeSettings: (settings: appModels.Settings) => void;
  readonly storeTrelloUser: (user: appModels.TrelloUser) => void;
  readonly trelloUser?: appModels.TrelloUser;
}

export interface BasePageState {
  readonly authModalCallback?: () => void;
  readonly authModalOpen: boolean;
  readonly authModalService?: 'Pomello' | 'Trello';
  readonly premiumModalOpen: boolean;
}

const mapStateToProps = (state: RootState) => ({
  appStatus: state.app.status,
  hasBootstrapped:
    (state.app.pomelloUser &&
      state.app.trelloUser &&
      state.app.boards &&
      state.app.lists) !== undefined,
  pomelloUser: state.app.pomelloUser,
  showOvertime: state.app.settings.overtime,
  trelloUser: state.app.trelloUser,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      createToast: toastActions.createToast,
      removePomelloUser: appActions.removePomelloUser,
      resetSettings: appActions.resetSettings,
      removeTrelloUser: appActions.removeTrelloUser,
      setAppStatus: appActions.setStatus,
      storeBoards: appActions.storeBoards,
      storeLists: appActions.storeLists,
      storePomelloUser: appActions.storePomelloUser,
      storeSettings: appActions.storeSettings,
      storeTrelloUser: appActions.storeTrelloUser,
    },
    dispatch
  );

export class BasePage extends Component<BasePageProps, BasePageState> {
  state: BasePageState = {
    authModalOpen: false,
    premiumModalOpen: false,
  };

  get isLoginPage() {
    return this.props.location.pathname.indexOf('/login') === 0;
  }

  get isConnectPage() {
    return this.props.location.pathname.indexOf('/connect') === 0;
  }

  componentWillMount() {
    this.bootstrap();

    storage.on('change', this.handleStorageChange);

    pomelloService.on('error', this.handlePomelloServiceError);
    trelloService.on('error', this.handleTrelloServiceError);

    setInterval(this.checkPomelloAccount, 60 * 1000);
  }

  componentDidUpdate(prevProps: BasePageProps) {
    if (this.props.hasBootstrapped && !prevProps.hasBootstrapped) {
      this.handleBootstrapSuccess();
    }
  }

  componentWillUnmount() {
    storage.removeListener('change', this.handleStorageChange);
  }

  handleAuthModalClose = () => {
    this.setState({
      authModalCallback: undefined,
      authModalOpen: false,
      authModalService: undefined,
    });
  };

  handleBootstrapSuccess = () => {
    this.props.setAppStatus('bootstrapped');
  };

  handlePomelloServiceError = (type: string) => {
    if (type === 'network' || type === 'server') {
      this.props.createToast('Cannot reach Pomello servers. Try again later.');
    } else if (type === 'auth') {
      this.logOutPomelloUser();

      this.setState({
        authModalCallback: () => this.props.history.replace('/login'),
        authModalOpen: true,
        authModalService: 'Pomello',
      });
    }
  };

  handlePremiumModalClose = () => {
    this.setState({ premiumModalOpen: false });
  };

  handlePremiumModalLogOutCallback = () => {
    this.logOutPomelloUser();

    this.props.history.push('/login');
  };

  handleTrelloServiceError = (type: string) => {
    if (type === 'network' || type === 'server') {
      this.props.createToast('Cannot reach Trello servers. Try again later.');
    } else if (type === 'auth') {
      this.logOutTrelloUser();

      this.setState({
        authModalCallback: () => (window.location.href = trelloService.authUrl),
        authModalOpen: true,
        authModalService: 'Trello',
      });
    }
  };

  handleStorageChange = (key: string, value: any, prevValue: any) => {
    if (key === 'pomelloToken' || key === 'trelloToken') {
      if (value && !prevValue) {
        this.bootstrap();
      }
    }
  };

  bootstrap = async () => {
    const { pomelloUser, setAppStatus, trelloUser } = this.props;

    if (this.checkForPomelloToken() === false) {
      return;
    }

    if (this.checkForTrelloToken() === false) {
      return;
    }

    if (this.isLoginPage || this.isConnectPage) {
      this.redirectIntendedOrDefault();
    }

    setAppStatus('bootstrapping');

    if (!pomelloUser) {
      this.fetchPomelloUserAndSettings();
    }

    if (!trelloUser) {
      this.fetchTrelloUserAndBoardsAndLists();
    }
  };

  checkForPomelloToken = () => {
    const { history } = this.props;

    const hasPomelloToken = storage.has('pomelloToken');

    if (!hasPomelloToken) {
      if (!this.isLoginPage) {
        this.storeRedirect();
        history.replace('/login');
      }

      return false;
    }

    return true;
  };

  checkForTrelloToken = () => {
    const { history } = this.props;

    const hasTrelloToken = storage.has('trelloToken');

    if (!hasTrelloToken) {
      if (!this.isConnectPage) {
        this.storeRedirect();
        history.replace('/connect');
      }
      return false;
    }

    return true;
  };

  checkPomelloAccount = () => {
    const { pomelloUser } = this.props;

    if (!pomelloUser || this.isLoginPage) {
      return;
    }

    if (pomelloUser.type !== 'premium') {
      this.setState({ premiumModalOpen: false }, () => {
        this.setState({ premiumModalOpen: true });
      });
    }
  };

  fetchPomelloUserAndSettings = async () => {
    const { storePomelloUser, storeSettings } = this.props;

    const [fetchUserResponse, fetchSettingsResponse] = await Promise.all([
      pomelloService.fetchUser(),
      pomelloService.fetchSettings(),
    ]);

    if (fetchUserResponse.success && fetchSettingsResponse.success) {
      storePomelloUser(fetchUserResponse.data);

      this.checkPomelloAccount();

      // If we have a timestamp that means the user's settings is in the DB,
      // otherwise they haven't stored their settings in the cloud yet
      if (fetchSettingsResponse.data.timestamp) {
        storeSettings(fetchSettingsResponse.data);
      }
    }
  };

  fetchTrelloUserAndBoardsAndLists = async () => {
    const { storeBoards, storeLists, storeTrelloUser } = this.props;

    const [fetchUserResponse, fetchBoardsAndListsResponse] = await Promise.all([
      trelloService.fetchUser(),
      trelloService.fetchBoardsAndLists(),
    ]);

    if (fetchUserResponse.success && fetchBoardsAndListsResponse.success) {
      storeTrelloUser(fetchUserResponse.data);
      storeBoards(fetchBoardsAndListsResponse.data.boards);
      storeLists(fetchBoardsAndListsResponse.data.lists);
    }
  };

  logOutPomelloUser = () => {
    storage.unset('pomelloToken');

    this.storeRedirect();

    const { removePomelloUser, resetSettings } = this.props;

    removePomelloUser();
    resetSettings();
  };

  logOutTrelloUser = () => {
    storage.unset('trelloToken');

    this.storeRedirect();

    this.props.removeTrelloUser();
  };

  storeRedirect = () => {
    const {
      location: { pathname },
    } = this.props;

    const hasRedirect = sessionStorage.getItem('redirect') !== null;
    if (!hasRedirect && !this.isLoginPage && !this.isConnectPage) {
      sessionStorage.setItem('redirect', pathname);
    }
  };

  redirectIntendedOrDefault = () => {
    const { history } = this.props;

    const redirect = sessionStorage.getItem('redirect') || '/';

    sessionStorage.removeItem('redirect');

    history.replace(redirect);
  };

  render() {
    const { appStatus, location, showOvertime } = this.props;
    const {
      authModalCallback,
      authModalOpen,
      authModalService,
      premiumModalOpen,
    } = this.state;

    return appStatus === 'bootstrapping' || appStatus === 'bootstrapped' ? (
      <>
        <App location={location} />
        {authModalCallback && authModalOpen && authModalService && (
          <AuthModal
            callback={authModalCallback}
            handleClose={this.handleAuthModalClose}
            service={authModalService}
          />
        )}
        {premiumModalOpen && (
          <PremiumModal
            logOutCallback={this.handlePremiumModalLogOutCallback}
            handleClose={this.handlePremiumModalClose}
          />
        )}
        <Triggers />
        <Timer />
        <Toasts />
        {showOvertime && <Overtime />}
      </>
    ) : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasePage);
