import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Login from '../../features/login';
import { ReactComponent as GoogleIcon } from '../../global/assets/google-icon.svg';
import { ReactComponent as PomelloWordmark } from '../../global/assets/pomello-wordmark.svg';
import storage from '../../global/helpers/storage';
import pomelloService, { baseURL } from '../../global/services/pomello';
import './loginPage.scss';

export interface LoginPageProps extends RouteComponentProps {}

export interface LoginPageState {
  readonly isGoogleCallback: boolean;
  readonly hasGoogleCallbackError: boolean;
}

export class LoginPage extends Component<LoginPageProps, LoginPageState> {
  state: LoginPageState = {
    isGoogleCallback: false,
    hasGoogleCallbackError: false,
  };

  componentWillMount() {
    const {
      location: { search },
    } = this.props;

    if (search.indexOf('?code=') === 0) {
      this.setState({ isGoogleCallback: true });

      this.authenticateGoogleUser();
    }
  }

  authenticateGoogleUser = async () => {
    const {
      location: { search },
    } = this.props;

    const response = await pomelloService.authGoogleUser(search);

    if (response.success) {
      storage.set('pomelloToken', response.data.token);
    } else {
      this.setState({ hasGoogleCallbackError: true });
    }
  };

  render() {
    const { isGoogleCallback, hasGoogleCallbackError } = this.state;

    if (!isGoogleCallback && !hasGoogleCallbackError) {
      return (
        <div className="login__container">
          <div className="login__content">
            <div className="login__wordmark">
              <PomelloWordmark width="112" height="40" fill="#00c1c1" />
            </div>
            <a
              href={`${baseURL}/login/google?pwa`}
              className="login__google button"
            >
              <GoogleIcon
                className="login__google-icon"
                width="18"
                height="18"
                fill="#fff"
              />
              Log in with Google
            </a>
            <div className="login__divider" />
            <Login />
          </div>
        </div>
      );
    } else if (isGoogleCallback && hasGoogleCallbackError) {
      return (
        <div className="login__container content">
          <p>Something went wrong</p>
          <a href="/login" className="button">
            Retry
          </a>
        </div>
      );
    }

    return null;
  }
}

export default LoginPage;
