import { createSelector } from 'reselect';
import { RootState } from '../../store/types';

const getBoards = (state: RootState) => state.app.boards;

const getLists = (state: RootState) => state.app.lists;

export const getParentTask = ({ app: { currentTask, tasks } }: RootState) => {
  if (currentTask && tasks) {
    const task = tasks[currentTask];

    if (task.shortIdCard) {
      return tasks[task.shortIdCard];
    }
  }

  return null;
};

export const getTask = (state: RootState) =>
  state.app.currentTask && state.app.currentTaskStartTime && state.app.tasks
    ? {
        ...state.app.tasks[state.app.currentTask],
        startTime: state.app.currentTaskStartTime,
      }
    : null;

export const getBoard = createSelector(
  getBoards,
  getTask,
  (boards, task) => (boards && task ? boards[task.shortIdBoard] : null)
);

export const getList = createSelector(
  getLists,
  getTask,
  (lists, task) => (lists && task ? lists[task.shortIdList] : null)
);

export const getOvertime = ({ overtime }: RootState) =>
  overtime.eventId && overtime.timestamp && overtime.type
    ? {
        active: overtime.active,
        eventId: overtime.eventId,
        taskId: overtime.taskId,
        taskIdCard: overtime.taskIdCard,
        time: overtime.time,
        timestamp: overtime.timestamp,
        type: overtime.type,
      }
    : null;

export const getTimer = (state: RootState) =>
  state.timer.type
    ? {
        active: state.timer.active,
        allottedTime: state.timer.startTime!,
        humanTime: state.timer.humanTime!,
        marker: state.timer.markers,
        paused: state.timer.paused,
        time: state.timer.time!,
        type: state.timer.type!,
      }
    : null;
