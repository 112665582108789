import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';
import taskVoided from './taskVoided';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  data: any = {}
) {
  return taskVoided.apply(this, [getVariable, data]);
}
