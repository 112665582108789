import { isNumber } from 'lodash';
import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {}
) {
  if (!getVariable('task')) {
    return;
  }

  const taskStartTime = getVariable('task.startTime.time');
  const timerTime = getVariable('timer.time');

  if (!isNumber(taskStartTime) || !isNumber(timerTime)) {
    throw new Error('Unable to calculate task duration');
  }

  return this.logEvent({
    type: 'task',
    start_time: getVariable('task.startTime.timestamp'),
    duration: taskStartTime - timerTime,
    service_id: getVariable('task.id'),
    parent_service_id: getVariable('task.idCard'),
    allotted_time: getVariable('timer.allottedTime'),
  });
}
