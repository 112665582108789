import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';
import { toastModels } from '..';
import { RootState } from '../../../store/types';
import './toast.scss';

export interface ToastsProps {
  readonly toast: toastModels.Toast | null;
}

export interface ToastState {
  readonly cachedToast: toastModels.Toast;
}

const mapStateToProps = (state: RootState) => ({
  toast: state.toast.current,
});

export class Toasts extends Component<ToastsProps, ToastState> {
  state: ToastState = {
    cachedToast: '',
  };

  componentWillUpdate(nextProps: ToastsProps) {
    if (nextProps.toast && nextProps.toast !== this.props.toast) {
      // Cache the toast, otherwise the message disappears on the exit animation
      this.setState({ cachedToast: nextProps.toast });
    }
  }

  render() {
    const { toast } = this.props;
    const { cachedToast } = this.state;

    return (
      <Transition in={toast !== null} timeout={150} unmountOnExit={true}>
        {status => (
          <div className="toast__container">
            <div className={`toast toast-${status}`}>{cachedToast}</div>
          </div>
        )}
      </Transition>
    );
  }
}

export default connect(mapStateToProps)(Toasts);
