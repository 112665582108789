import React, { MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { appActions, appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import { triggersEmitter } from '../../features/triggers';
import getTaskName from '../../global/helpers/getTaskName';
import storage from '../../global/helpers/storage';
import { RootAction, RootState } from '../../store/types';
import { getLists } from './selectors';
import { TaskLists } from './TaskLists';

export type TaskDebriefProps = TaskDebriefOwnProps &
  TaskDebriefStateProps &
  TaskDebriefDispatchProps;

export interface TaskDebriefOwnProps {
  readonly board?: appModels.Board;
  readonly bootstrapped: appModels.Bootstrapped;
  readonly handleCheckItemComplete: () => void;
  readonly handleNoteModalToggle: () => void;
  readonly handleTaskMove: (idList: string, shortIdList: string) => void;
  readonly isCheckItem: boolean;
  readonly lastUsedListId?: string;
  readonly routeToBreak: (autoStart?: boolean, isContinuing?: boolean) => void;
  readonly task?: appModels.Task;
}

interface TaskDebriefStateProps {
  readonly lists?: appModels.List[];
  readonly nextBreak: string;
  readonly taskTime: appModels.Settings['taskTime'];
}

interface TaskDebriefDispatchProps {
  readonly unsetCurrentTask: () => void;
}

const mapStateToProps = (state: RootState, props: TaskDebriefOwnProps) => ({
  lists: getLists(state, props),
  nextBreak: state.app.nextBreak,
  taskTime: state.app.settings.taskTime,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    { unsetCurrentTask: appActions.unsetCurrentTask },
    dispatch
  );

export class TaskDebrief extends PureComponent<TaskDebriefProps> {
  get lastUsedListId() {
    const { board } = this.props;
    return board ? storage.get(`lastUsedLists.${board.shortId}`) : undefined;
  }

  handleBreakClick = (action: string, event: MouseEvent<HTMLButtonElement>) => {
    const { routeToBreak, unsetCurrentTask } = this.props;

    event.preventDefault();

    if (action === 'switch') {
      unsetCurrentTask();
    }

    routeToBreak(true, action === 'continue');
  };

  handleCheckItemComplete = () => {
    const {
      handleCheckItemComplete,
      routeToBreak,
      unsetCurrentTask,
    } = this.props;

    unsetCurrentTask();
    handleCheckItemComplete();
    routeToBreak(true);
  };

  handleNoteAdd = () => {
    this.props.handleNoteModalToggle();
  };

  handleTaskMove = (idList: string, shortIdList: string) => {
    const { handleTaskMove, routeToBreak } = this.props;

    handleTaskMove(idList, shortIdList);

    routeToBreak(true);
  };

  handleTaskVoid = () => {
    triggersEmitter.emit('event', 'taskVoided', {
      allottedTime: this.props.taskTime,
    });

    this.props.routeToBreak(true, true);
  };

  render() {
    const {
      bootstrapped,
      isCheckItem,
      lastUsedListId,
      lists,
      task,
    } = this.props;

    return (
      <>
        <AppBar title="Pomodoro completed" />
        {bootstrapped && (
          <div>
            {task && (
              <div className="secondary-bar is-small">
                <p>
                  <strong>Task:</strong> {getTaskName(task.name)}
                </p>
              </div>
            )}
            <div className="list">
              <button
                className="list__item"
                onClick={this.handleBreakClick.bind(this, 'continue')}
              >
                <span className="list__content">Continue after break</span>
              </button>
              <button
                className="list__item"
                onClick={this.handleBreakClick.bind(this, 'switch')}
              >
                <span className="list__content">Switch tasks after break</span>
              </button>
              <button className="list__item" onClick={this.handleTaskVoid}>
                <span className="list__content">Void this pomodoro</span>
              </button>
              <button className="list__item" onClick={this.handleNoteAdd}>
                <span className="list__content">Add a note first</span>
              </button>
              {isCheckItem && (
                <button
                  className="list__item"
                  onClick={this.handleCheckItemComplete}
                >
                  <span className="list__content">Mark item complete</span>
                </button>
              )}
            </div>
            {!isCheckItem && (
              <TaskLists
                handleListClick={this.handleTaskMove}
                lastUsedListId={lastUsedListId}
                lists={lists}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDebrief);
