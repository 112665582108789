import React, { SFC } from 'react';
import Placeholder from '../../features/placeholder';

const ListsPlaceholder: SFC = () => (
  <>
    {[60, 72, 64].map(size => (
      <div key={size} className="tasks-page__list">
        <Placeholder size={size} />
      </div>
    ))}
  </>
);

export default ListsPlaceholder;
