import React, { SFC } from 'react';
import Modal from '../../features/modal';

interface AuthModalProps {
  callback: () => void;
  handleClose: () => void;
  service: string;
}

const AuthModal: SFC<AuthModalProps> = ({ callback, handleClose, service }) => (
  <Modal buttons={[{ label: 'Log in', callback }]} handleClose={handleClose}>
    <h6>Authorization error</h6>
    You are no longer logged into {service}.
  </Modal>
);

export default AuthModal;
