import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { overtimeActions, overtimeModels } from '..';
import { getTask } from '../../../global/selectors/getTask';
import { RootAction, RootState } from '../../../store/types';
import { appModels } from '../../app';
import { triggersEmitter } from '../../triggers';
import { getEventId } from '../selectors';
import './overtime.scss';

type OvertimeProps = Readonly<OvertimeOwnProps & OvertimeDispatchProps>;

interface OvertimeOwnProps {
  active: overtimeModels.Active;
  delay: appModels.Settings['overtimeDelay'];
  eventId: string | null;
  task: appModels.Task | null;
  humanTime: overtimeModels.HumanTime;
  timerActive: boolean;
  timerType?: string;
  type: overtimeModels.Type | null;
}

interface OvertimeDispatchProps {
  destroyOvertime: () => void;
  incrementTime: () => void;
  startOvertime: (
    eventId: overtimeModels.EventId,
    task: appModels.Task | null,
    time: overtimeModels.Time,
    type: overtimeModels.Type
  ) => void;
}

const mapStateToProps = (state: RootState) => ({
  active: state.overtime.active,
  delay: state.app.settings.overtimeDelay,
  eventId: getEventId(state),
  task: getTask(state),
  humanTime: state.overtime.humanTime,
  timerActive: state.timer.active,
  timerType: state.timer.type,
  type: state.overtime.type,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      destroyOvertime: overtimeActions.destroyOvertime,
      incrementTime: overtimeActions.incrementTime,
      startOvertime: overtimeActions.startOvertime,
    },
    dispatch
  );

class Overtime extends Component<OvertimeProps> {
  delayTimeoutId?: number;

  tickTimeoutId?: number;

  componentDidUpdate = (prevProps: OvertimeProps) => {
    if (
      !this.props.timerActive &&
      prevProps.timerActive &&
      prevProps.timerType
    ) {
      this.handleTimerEnd(prevProps.eventId, prevProps.timerType);
    } else if (this.props.timerActive && !prevProps.timerActive) {
      this.handleTimerStart();
    }
  };

  handleTimerEnd = (eventId: string | null, timerType: string) => {
    const { delay, startOvertime, task } = this.props;

    const type = timerType.replace(/short-|long-/, '');

    this.delayTimeoutId = window.setTimeout(() => {
      startOvertime(eventId, task, delay, type);

      this.tickOvertime();
    }, delay * 1000);
  };

  handleTimerStart = () => {
    const { active, destroyOvertime } = this.props;

    if (this.delayTimeoutId) {
      window.clearTimeout(this.delayTimeoutId);
    }

    if (this.tickTimeoutId) {
      window.clearTimeout(this.tickTimeoutId);
    }

    if (active) {
      triggersEmitter.emit('event', 'overtimeEnded');

      destroyOvertime();
    }
  };

  tickOvertime = () => {
    const interval = 1000;
    let expected = Date.now() + interval;

    const step = () => {
      const drift = Date.now() - expected;
      const newTimeout = Math.max(0, interval - drift);

      expected += interval;

      this.props.incrementTime();

      if (this.tickTimeoutId) {
        this.tickTimeoutId = window.setTimeout(step, newTimeout);
      }
    };

    this.tickTimeoutId = window.setTimeout(step, interval);
  };

  render() {
    const { active, humanTime, type } = this.props;

    return active ? (
      <div className="overtime">
        <span className={`overtime__popup`}>
          <span className="overtime__time">{humanTime}</span> since {type} end
        </span>
      </div>
    ) : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overtime);
