import React, { SFC } from 'react';
import Modal from './features/modal';

interface UpdateModalProps {
  readonly handleClose: () => void;
  readonly updateServiceWorker: () => void;
}

const UpdateModal: SFC<UpdateModalProps> = ({
  handleClose,
  updateServiceWorker,
}) => (
  <Modal
    buttons={[
      {
        label: 'Later',
      },
      {
        label: 'Restart',
        callback: updateServiceWorker,
      },
    ]}
    handleClose={handleClose}
  >
    <h6>Update available</h6>
    The update will automatically be applied the next time you open Pomello.
  </Modal>
);

export default UpdateModal;
