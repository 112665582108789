import { throttle } from 'lodash';
import React, { SFC, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import Modal from '../../features/modal';
import { SettingName, SettingValue, SlidersSetting } from './SettingsPage';

interface InputSliders {
  setting: SlidersSetting;
  updateSetting: (name: SettingName, value: SettingValue) => void;
  values: { [key: string]: number };
}

const InputSliders: SFC<InputSliders> = ({
  setting,
  updateSetting,
  values,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSliderChange = throttle(
    (name, value) => updateSetting(name, value),
    150,
    { leading: false }
  );

  return (
    <>
      <button
        className="button is-small is-text"
        onClick={() => setModalOpen(true)}
      >
        Set
      </button>
      {modalOpen && (
        <Modal
          buttons={[
            {
              label: 'Done',
            },
          ]}
          handleClose={() => setModalOpen(false)}
        >
          <div className="content">
            <h6 className="heading">{setting.label}</h6>
            {setting.sliders.map(({ label, value }) => (
              <div key={value}>
                <label htmlFor={value} className="label">
                  {label}
                </label>
                <div className="range">
                  <Range
                    step={0.1}
                    min={0}
                    max={1}
                    renderTrack={({ children, props }) => (
                      <div
                        {...props}
                        className="range__track"
                        style={{
                          background: getTrackBackground({
                            values: [values[value]],
                            colors: ['#3273dc', 'rgba(50, 115, 220, 0.16)'],
                            min: 0,
                            max: 1,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ index, props }) => (
                      <div
                        className="range__thumb"
                        key={index}
                        style={props.style}
                      />
                    )}
                    onChange={([vol]) => updateSetting(value, vol)}
                    values={[values[value]]}
                  />
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default InputSliders;
