import { History, Location } from 'history';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { appActions, appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import MiniClock from '../../features/miniClock';
import getTaskName from '../../global/helpers/getTaskName';
import storage from '../../global/helpers/storage';
import { RootAction, RootState } from '../../store/types';
import { getLists } from './selectors';
import { TaskLists } from './TaskLists';

export type TaskFinishedProps = TaskFinishedOwnProps &
  TaskFinishedStateProps &
  TaskFinishedDispatchProps;

export interface TaskFinishedOwnProps {
  readonly board?: appModels.Board;
  readonly bootstrapped: appModels.Bootstrapped;
  readonly handleNoteModalToggle: () => void;
  readonly handleTaskMove: (idList: string, shortIdList: string) => void;
  readonly history: History;
  readonly lastUsedListId?: string;
  readonly location: Location;
  readonly routeToBreak: (autoStart?: boolean, isContinuing?: boolean) => void;
  readonly task?: appModels.Task;
  readonly timerActive: boolean;
}

interface TaskFinishedStateProps {
  readonly lists?: appModels.List[];
}

interface TaskFinishedDispatchProps {
  readonly unsetCurrentTask: () => void;
}

const mapStateToProps = (state: RootState, props: TaskFinishedOwnProps) => ({
  lists: getLists(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    { unsetCurrentTask: appActions.unsetCurrentTask },
    dispatch
  );

export class TaskFinished extends PureComponent<TaskFinishedProps> {
  get lastUsedListId() {
    const { board } = this.props;
    return board ? storage.get(`lastUsedLists.${board.shortId}`) : undefined;
  }

  componentDidMount() {
    this.props.unsetCurrentTask();
  }

  handleNoteAdd = () => {
    this.props.handleNoteModalToggle();
  };

  handleTaskMove = (idList: string, shortIdList: string) => {
    this.props.handleTaskMove(idList, shortIdList);

    this.goToNextRoute();
  };

  goToNextRoute = () => {
    const { history, routeToBreak, timerActive } = this.props;

    if (timerActive) {
      history.push('/');
    } else {
      routeToBreak(true);
    }
  };

  render() {
    const {
      bootstrapped,
      lastUsedListId,
      lists,
      task,
      timerActive,
    } = this.props;

    return (
      <>
        <AppBar title="Task finished" />
        {bootstrapped && (
          <div>
            {task && (
              <div className="secondary-bar is-small">
                <p>
                  <strong>Task:</strong> {getTaskName(task.name)}
                </p>
              </div>
            )}
            <div className="list">
              <button className="list__item" onClick={this.goToNextRoute}>
                <span className="list__content">Pick a new task</span>
              </button>
              <div className="list">
                <button className="list__item" onClick={this.handleNoteAdd}>
                  <span className="list__content">Add a note first</span>
                </button>
              </div>
            </div>
            <TaskLists
              handleListClick={this.handleTaskMove}
              lastUsedListId={lastUsedListId}
              lists={lists}
            />
            {timerActive && <MiniClock />}
          </div>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskFinished);
