import { camelCase, isEmpty } from 'lodash';
import { $Values, Omit } from 'utility-types';
import storage from '../../../global/helpers/storage';
import defaultBoardAndListPrefs from '../../../pages/boardsAndLists/defaultPreferences.json';
import { ServicePreferences } from '../../../pages/boardsAndLists/ServicesPage';
import { Trigger, TriggerSchema } from '../../triggers/components/Triggers';

type Settings = $Values<ServicePreferences>;

type BoardsAndLists = keyof Omit<Settings, 'global'>;

const makeCondition = (settings: Settings, type: string, action: string) => {
  const comparator = action === 'includes' ? 'contains' : 'notContains';
  const list = camelCase(`${type} ${action}`) as BoardsAndLists;

  return {
    comparator,
    left: {
      value: `${type}.shortId`,
      type: 'variable',
    },
    right: Object.keys(settings[list]),
  };
};

const makeConditions = (action: string, settings: Settings) => {
  const conditions = getBaseConditions(action);

  if (!isEmpty(settings.listExcludes)) {
    conditions.push(makeCondition(settings, 'list', 'excludes'));
  }

  const userConditions = [];

  if (settings.global && !isEmpty(settings.boardExcludes)) {
    userConditions.push(makeCondition(settings, 'board', 'excludes'));
  } else if (!settings.global && !isEmpty(settings.boardIncludes)) {
    userConditions.push(makeCondition(settings, 'board', 'includes'));
  }

  if (
    !isEmpty(settings.listIncludes) &&
    (!settings.global || !isEmpty(settings.boardExcludes))
  ) {
    userConditions.push(makeCondition(settings, 'list', 'includes'));
  }

  if (userConditions.length) {
    conditions.push(userConditions);
  } else {
    return settings.global ? conditions : [];
  }

  return conditions;
};

const getBaseConditions = (action: string): Trigger['conditions'] => {
  switch (action) {
    case 'updateTrelloCardName':
      return [
        {
          comparator: 'equals',
          left: {
            value: 'timer.type',
            type: 'variable',
          },
          right: 'task',
        },
      ];
    default:
      return [];
  }
};

const getEventName = (action: string) => {
  switch (action) {
    case 'updateTrelloCardName':
      return 'timerEnded';
    case 'logPomelloEvent':
      return 'pomelloEvent';
    case 'archiveTrelloCard':
      return 'taskMoved';
    default:
      throw new Error(`Cannot get event name. Unknown action "${action}"`);
  }
};

export const generateTriggerSchema = () => {
  const schema: TriggerSchema = {};

  const servicePreferences: ServicePreferences =
    storage.get('boardAndListPrefs') || defaultBoardAndListPrefs;

  Object.keys(servicePreferences).forEach(action => {
    const settings = servicePreferences[action as keyof ServicePreferences];

    const conditions = makeConditions(action, settings);

    const eventName = getEventName(action);

    if (settings.global === true || conditions.length) {
      schema[eventName] = [
        {
          conditions,
          actions: [{ action }],
        },
      ];
    }
  });

  return schema;
};
