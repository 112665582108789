import axios from 'axios';
import { PomelloService } from '..';
import { appModels } from '../../../../features/app';

type AuthGoogleUserSuccess = {
  success: true;
  data: {
    token: string;
    user: appModels.PomelloUser;
  };
};

type AuthGoogleUserFailure = {
  success: false;
};

export default async function(
  this: PomelloService,
  params: string
): Promise<AuthGoogleUserSuccess | AuthGoogleUserFailure> {
  try {
    const response = await axios.get(
      `${PomelloService.baseURL}/login/google/callback${params}&pwa`
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false };
  }
}
