import classnames from 'classnames';
import { debounce } from 'lodash';
import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootAction, RootState } from '../../../store/types';
import Placeholder from '../../placeholder';
import { timerActions } from '../../timer';
import './dial.scss';

export type DialProps = DialOwnProps;

interface DialOwnProps {
  actionsCallback: () => void;
  canPause: boolean;
  pauseTimer: () => void;
  resumeTimer: () => void;
  startCallback: (event: MouseEvent<HTMLButtonElement>) => void;
  startTimer: () => void;
  time?: string;
  timerActive: boolean;
  timerPaused: boolean;
}

const mapStateToProps = (state: RootState) => ({
  time: state.timer.humanTime,
  timerActive: state.timer.active,
  timerPaused: state.timer.paused,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pauseTimer: timerActions.pauseTimer,
      resumeTimer: timerActions.resumeTimer,
      startTimer: timerActions.startTimer,
    },
    dispatch
  );

export class Dial extends Component<DialProps> {
  handleDialClick = debounce(
    (event: MouseEvent<HTMLButtonElement>) => {
      const {
        canPause,
        pauseTimer,
        resumeTimer,
        startCallback,
        startTimer,
        time,
        timerActive,
        timerPaused,
      } = this.props;

      if (!time || (timerActive && !canPause)) {
        return;
      }

      if (timerActive && !timerPaused) {
        pauseTimer();
      } else if (timerActive && timerPaused) {
        resumeTimer();
      } else {
        startTimer();

        if (startCallback) {
          startCallback(event);
        }
      }
    },
    800,
    { leading: true, trailing: false }
  );

  render() {
    const { actionsCallback, time, timerActive, timerPaused } = this.props;

    return (
      <>
        <button
          className={classnames('dial__container', {
            'is-paused': timerPaused,
          })}
          role="button"
          onClick={this.handleDialClick}
        >
          <div className="dial__time">{time}</div>
        </button>
        <div className="dial__footer">
          {!timerActive && (
            <button className="dial__start" onClick={this.handleDialClick}>
              {time ? 'Press to start' : <Placeholder size={100} />}
            </button>
          )}
          {actionsCallback && timerActive && (
            <button
              className="dial__actions"
              role="button"
              onClick={actionsCallback}
            >
              <span />
              <span />
              <span />
            </button>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dial);
