import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';
import taskTimerEnded from './taskTimerEnded';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {}
) {
  return taskTimerEnded.apply(this, [getVariable, meta]);
}
