import { ThunkAction } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';
import { ToastAction, toastModels } from '.';
import { RootState } from '../../store/types';

const END_QUEUE = 'toast/END_QUEUE';
const PROCESS_QUEUE = 'toast/PROCESS_QUEUE';
const QUEUE_TOAST = 'toast/QUEUE_TOAST';
const REMOVE_TOAST = 'toast/REMOVE_TOAST';

export const endQueue = createStandardAction(END_QUEUE)();

export const processToastQueue = createStandardAction(PROCESS_QUEUE)();

export const queueToast = createStandardAction(QUEUE_TOAST)<
  toastModels.Toast
>();

export const removeToast = createStandardAction(REMOVE_TOAST)();

export const createToast = (
  text: string
): ThunkAction<void, RootState, undefined, ToastAction> => (
  dispatch,
  getState
) => {
  const {
    toast: { isProcessingQueue },
  } = getState();

  dispatch(queueToast(text));

  if (!isProcessingQueue) {
    dispatch(processQueue());
  }
};

const processQueue = (): ThunkAction<
  void,
  RootState,
  undefined,
  ToastAction
> => (dispatch, getState) => {
  dispatch(processToastQueue());

  setTimeout(() => {
    dispatch(removeToast());

    setTimeout(() => {
      const {
        toast: { queue },
      } = getState();

      if (queue.length) {
        dispatch(processQueue());
      } else {
        dispatch(endQueue());
      }
    }, 500);
  }, 5000);
};
