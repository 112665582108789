import React, { SFC } from 'react';
import Placeholder from '../../features/placeholder';

const TasksPlaceholder: SFC = () => (
  <>
    {[150, 200, 180].map(size => (
      <div key={size} className="list__item">
        <span className="list__content">
          <Placeholder size={size} />
          &nbsp;
        </span>
      </div>
    ))}
  </>
);

export default TasksPlaceholder;
