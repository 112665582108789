import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { appActions, appModels } from '../../features/app';
import NoteModal from '../../features/noteModal';
import { triggersEmitter } from '../../features/triggers';
import storage from '../../global/helpers/storage';
import wakeLock from '../../global/helpers/wakeLock';
import trelloService from '../../global/services/trello';
import { RootAction, RootState } from '../../store/types';
import { getBoard, getTask } from './selectors';
import TaskClock from './TaskClock';
import TaskDebrief from './TaskDebrief';
import TaskFinished from './TaskFinished';

type RouteParams = {
  readonly idCard: string;
  readonly idChecklist?: string;
};

type RouteState = {
  readonly autoStart?: boolean;
};

export type TaskPageProps = TaskPageOwnProps &
  TaskPageStateProps &
  TaskDispatchProps;

export interface TaskPageOwnProps
  extends RouteComponentProps<RouteParams, any, RouteState> {}

interface TaskPageStateProps {
  readonly autoStartBreaks: appModels.Settings['autoStartBreaks'];
  readonly board?: appModels.Board;
  readonly bootstrapped: appModels.Bootstrapped;
  readonly currentList: appModels.CurrentList;
  readonly movedTaskPos: appModels.Settings['completedTaskPosition'];
  readonly nextBreak: appModels.NextBreak;
  readonly task?: appModels.Task;
  readonly timerActive: boolean;
}

interface TaskDispatchProps {
  readonly moveTaskToList: (
    payload: {
      shortIdTask: appModels.Task['shortId'];
      shortIdList: appModels.List['shortId'];
    }
  ) => void;
  readonly removeTaskCheckItem: (task: appModels.Task['shortId']) => void;
  readonly storeListTasks: (payload: appModels.ListTasksPayload) => void;
  readonly storeTasks: (tasks: appModels.Tasks) => void;
  readonly unsetCurrentTask: () => void;
}

export interface TaskPageState {
  readonly noteModalVisible: boolean;
  readonly view: 'clock' | 'debrief' | 'finished';
}

const mapStateToProps = (state: RootState, props: TaskPageOwnProps) => ({
  autoStartBreaks: state.app.settings.autoStartBreaks,
  board: getBoard(state, props),
  bootstrapped: state.app.bootstrapped,
  currentList: state.app.currentList,
  movedTaskPos: state.app.settings.completedTaskPosition,
  nextBreak: state.app.nextBreak,
  task: getTask(state, props),
  timerActive: state.timer.type === 'task' && state.timer.active,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      moveTaskToList: appActions.moveTaskToList,
      removeTaskCheckItem: appActions.removeTaskCheckItem,
      storeListTasks: appActions.storeListTasks,
      storeTasks: appActions.storeTasks,
      unsetCurrentTask: appActions.unsetCurrentTask,
    },
    dispatch
  );

export class TaskPage extends Component<TaskPageProps, TaskPageState> {
  state: TaskPageState = {
    noteModalVisible: false,
    view: 'clock',
  };

  get lastUsedListId() {
    const { board } = this.props;
    return board ? storage.get(`lastUsedLists.${board.shortId}`) : undefined;
  }

  componentDidMount() {
    const { bootstrapped, task } = this.props;

    if (bootstrapped && !task) {
      this.fetchTask();
    }
  }

  componentDidUpdate(prevProps: TaskPageProps) {
    if (
      this.props.bootstrapped &&
      !this.props.task &&
      !prevProps.bootstrapped
    ) {
      this.fetchTask();
    } else if (!this.props.timerActive && prevProps.timerActive) {
      this.handleTaskTimerEnd();
    }
  }

  handleCheckItemComplete = () => {
    const { removeTaskCheckItem, task } = this.props;

    if (task && task.idCard) {
      removeTaskCheckItem(task.shortId);

      trelloService.markCheckItemComplete(task.idCard, task.id);
    }
  };

  handleNoteModalToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      noteModalVisible: !prevState.noteModalVisible,
    }));
  };

  handleTaskFinish = () => {
    const { history, task, unsetCurrentTask } = this.props;

    if (task && task.idCard) {
      this.handleCheckItemComplete();

      unsetCurrentTask();

      history.push('/');
    } else {
      this.setState({ view: 'finished' });
    }
  };

  handleTaskMove = (idList: string, shortIdList: string) => {
    const {
      board,
      movedTaskPos,
      moveTaskToList,
      task,
      unsetCurrentTask,
    } = this.props;

    if (board && task && task.idList !== idList) {
      triggersEmitter.emit('event', 'taskMoved', {
        targetList: idList,
      });

      trelloService.updateCard(task, { idList, pos: movedTaskPos });

      moveTaskToList({ shortIdTask: task.shortId, shortIdList: shortIdList });

      storage.set(`lastUsedLists.${board.shortId}`, idList);
    }

    unsetCurrentTask();
  };

  handleTaskTimerEnd = () => {
    if (this.props.autoStartBreaks) {
      this.routeToBreak(true, true);
    } else if (this.state.view === 'clock') {
      this.setState({ view: 'debrief' });
    }
  };

  fetchTask = async () => {
    const {
      match: {
        params: { idCard },
      },
    } = this.props;

    const response = await trelloService.fetchCard(idCard);

    if (response.success) {
      return this.fetchTasks(response.data.shortIdList);
    }
  };

  fetchTasks = async (idList: string) => {
    const { storeListTasks, storeTasks } = this.props;

    const response = await trelloService.fetchCardsAndChecklists(idList);

    if (response.success) {
      storeTasks(response.data.tasks);
      storeListTasks({
        idList,
        listTasks: response.data.listsTasks,
      });
    }
  };

  routeToBreak = (
    autoStart: boolean = false,
    isContinuing: boolean = false
  ) => {
    const {
      history,
      location: { pathname },
      nextBreak,
    } = this.props;

    const nextRoute = isContinuing ? pathname : '/';

    const state = { autoStart, nextRoute };

    history.push(`/${nextBreak}-break`, state);

    wakeLock.enableIfNecessary();
  };

  render() {
    const {
      board,
      bootstrapped,
      history,
      location,
      task,
      timerActive,
    } = this.props;

    const { noteModalVisible, view } = this.state;

    return (
      <>
        {(() => {
          switch (view) {
            case 'clock':
              return (
                <TaskClock
                  autoStart={
                    location.state && location.state.autoStart === true
                  }
                  board={board}
                  bootstrapped={bootstrapped}
                  handleNoteModalToggle={this.handleNoteModalToggle}
                  handleTaskFinish={this.handleTaskFinish}
                  history={history}
                  routeToBreak={this.routeToBreak}
                  task={task}
                />
              );
            case 'debrief':
              return (
                <TaskDebrief
                  board={board}
                  bootstrapped={bootstrapped}
                  handleCheckItemComplete={this.handleCheckItemComplete}
                  handleNoteModalToggle={this.handleNoteModalToggle}
                  handleTaskMove={this.handleTaskMove}
                  isCheckItem={task !== undefined && task.idCard !== undefined}
                  lastUsedListId={this.lastUsedListId}
                  routeToBreak={this.routeToBreak}
                  task={task}
                />
              );
            case 'finished':
              return (
                <TaskFinished
                  board={board}
                  bootstrapped={bootstrapped}
                  handleNoteModalToggle={this.handleNoteModalToggle}
                  handleTaskMove={this.handleTaskMove}
                  history={history}
                  lastUsedListId={this.lastUsedListId}
                  location={location}
                  routeToBreak={this.routeToBreak}
                  task={task}
                  timerActive={timerActive}
                />
              );
            default:
              return null;
          }
        })()}
        {noteModalVisible && (
          <NoteModal handleClose={this.handleNoteModalToggle} task={task} />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskPage);
