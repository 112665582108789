import NoSleep from 'nosleep.js';
import store from '../../store';

class WakeLock {
  wakeLock = new NoSleep();

  wakeLockEnabled: boolean = false;

  enableIfNecessary() {
    const {
      pwaPreventDisplaySleep: wakeLockSetting,
    } = store.getState().app.settings;

    if (wakeLockSetting && !this.wakeLockEnabled) {
      this.wakeLock.enable();
      this.wakeLockEnabled = true;
    }
  }

  disableIfNecessary() {
    if (this.wakeLockEnabled) {
      this.wakeLock.disable();
      this.wakeLockEnabled = false;
    }
  }
}

export default new WakeLock();
