import { get } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { triggersEmitter } from '..';
import storage from '../../../global/helpers/storage';
import { RootState } from '../../../store/types';
import { appModels } from '../../app';
import { overtimeModels } from '../../overtime';
import * as triggerActions from '../actions';
import {
  getBoard,
  getList,
  getOvertime,
  getParentTask,
  getTask,
  getTimer,
} from '../selectors';
import { generateTriggerSchema } from './helpers';

export type Trigger = {
  conditions: Condition[];
  actions: Action[];
};

type Condition = SingleCondition | GroupCondition;

type SingleCondition = {
  left: ConditionItem;
  comparator: string;
  right: ConditionItem;
};

export type GroupCondition = SingleCondition[];

type ConditionItem = string | string[] | number | ConditionVariable;

type ConditionVariable = {
  value: string;
  type: string;
};

type Action = {
  action: string;
  data?: any;
};

export type GetSnapshotVariable = (variable: ConditionVariable['value']) => any;

export interface TriggerSchema {
  [key: string]: Trigger[];
}

type TriggerProps = TriggersStateProps;

interface TriggersStateProps {
  board: appModels.Board | null;
  list: appModels.List | null;
  overtime: {
    active: overtimeModels.Active;
    eventId: overtimeModels.EventId;
    taskId: appModels.Task['id'] | null;
    taskIdCard: appModels.Task['idCard'] | null;
    time: overtimeModels.Time;
    timestamp: overtimeModels.Timestamp | null;
    type: overtimeModels.Type;
  } | null;
  parentTask: appModels.Task | null;
  settings: appModels.Settings;
  task: appModels.Task | null;
  timer: {
    active: boolean;
    allottedTime: number;
    humanTime: string;
    paused: boolean;
    time: number;
    type: string;
  } | null;
}

const mapStateToDispatch = (state: RootState) => ({
  board: getBoard(state),
  list: getList(state),
  overtime: getOvertime(state),
  parentTask: getParentTask(state),
  settings: state.app.settings,
  task: getTask(state),
  timer: getTimer(state),
});

class Triggers extends Component<TriggerProps> {
  triggers = generateTriggerSchema();

  componentDidMount() {
    triggersEmitter.on('event', this.handleEvent);

    storage.on('change', this.handleStorageChange);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    triggersEmitter.removeAllListeners();
  }

  handleEvent = async (event: string, meta = {}) => {
    const eventTriggers = this.triggers[event];

    const shouldLogPomelloEvent =
      this.triggers.hasOwnProperty('pomelloEvent') &&
      !(
        event === 'timerStarted' ||
        event === 'timerTicked' ||
        event === 'timerPaused'
      );

    if (shouldLogPomelloEvent || eventTriggers) {
      const getVariable = this.getVariable.bind(this, { ...this.props });

      if (shouldLogPomelloEvent) {
        await this.handlePomelloEvent(event, getVariable, meta);
      }

      if (eventTriggers) {
        this.handleTriggers(eventTriggers, getVariable, meta);
      }
    }
  };

  handlePomelloEvent = (
    event: string,
    getVariable: GetSnapshotVariable,
    meta: any
  ) => {
    const trigger = this.triggers.pomelloEvent[0];

    if (this.filterPassedConditions(trigger.conditions, getVariable)) {
      return triggerActions.logPomelloEvent({ event }, getVariable, meta);
    }
  };

  handleStorageChange = (key: string) => {
    if (key === 'boardAndListPrefs') {
      this.triggers = generateTriggerSchema();
    }
  };

  handleTriggers = (
    triggers: Trigger[],
    getVariable: GetSnapshotVariable,
    meta: any
  ) => {
    triggers
      .filter(({ conditions }) =>
        this.filterPassedConditions(conditions, getVariable)
      )
      .forEach(({ actions }) =>
        actions.forEach(action => this.executeAction(action, getVariable, meta))
      );
  };

  executeAction = (
    { action, data }: Action,
    getVariable: GetSnapshotVariable,
    meta: any
  ) => {
    const triggerAction = triggerActions[action as keyof typeof triggerActions];

    if (typeof triggerAction === 'undefined') {
      throw new Error(`Unknown action [${action}]`);
    }

    triggerAction(data, getVariable, meta);
  };

  filterPassedConditions = (
    conditions: Trigger['conditions'],
    getVariable: GetSnapshotVariable
  ) => {
    const compareCondition = (condition: SingleCondition) => {
      const left = this.getValue(condition.left, getVariable);
      const right = this.getValue(condition.right, getVariable);

      return this.compare(condition.comparator, left, right);
    };

    return !conditions.some(condition => {
      if (Array.isArray(condition)) {
        // If we have a conditon group, only one of them needs to pass
        return !condition.some(compareCondition);
      }

      return !compareCondition(condition);
    });
  };

  compare(comparator: string, left: any, right: any) {
    switch (comparator) {
      case 'equals':
        return left === right;
      case 'notEquals':
        return left !== right;
      case 'contains':
        return right.includes(left);
      case 'notContains':
        return !right.includes(left);
      default:
        throw new Error(`Unknown comparator [${comparator}]`);
    }
  }

  getValue(key: ConditionItem, getVariable: GetSnapshotVariable) {
    return !Array.isArray(key) &&
      typeof key === 'object' &&
      key.type === 'variable'
      ? getVariable(key.value)
      : key;
  }

  getVariable(snapshot: TriggerProps, variable: string) {
    return get(snapshot, variable, null);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToDispatch)(Triggers);
