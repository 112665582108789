import EventEmitter from 'events';
import { get, has, set, unset } from 'lodash';

interface Storage {
  namespace: string;
  store: object;
}

class Storage extends EventEmitter {
  constructor() {
    super();

    this.namespace = 'pomello';

    const item = localStorage.getItem(this.namespace) || '{}';
    this.store = JSON.parse(item);
  }

  has(key: string) {
    return has(this.store, key);
  }

  get(key: string) {
    return get(this.store, key);
  }

  set(key: string, value: any) {
    const prevValue = get(this.store, key);

    this.store = set(this.store, key, value);

    this.updateLocalStorage();

    this.emit('change', key, value, prevValue);
  }

  unset(key: string) {
    const prevValue = get(this.store, key);

    if (unset(this.store, key)) {
      this.updateLocalStorage();

      this.emit('change', key, undefined, prevValue);
    }
  }

  updateLocalStorage() {
    const storeJSON = JSON.stringify(this.store);
    localStorage.setItem(this.namespace, storeJSON);
  }
}

export default new Storage();
