import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Overwrite } from 'utility-types';
import { appModels } from '../../features/app';
import { RootState } from '../../store/types';
import { TasksPageProps } from './TasksPage';

export type Board =
  | {
      lists: appModels.List[];
    } & appModels.Board
  | undefined;

export type Task = Overwrite<
  appModels.TaskCard,
  {
    checklists: Array<
      Overwrite<
        appModels.TaskCardChecklist,
        {
          checkItems: appModels.TaskCheckItem[];
        }
      >
    >;
  }
>;

export type Tasks = Task[] | undefined;

const getBoards = (state: RootState) => state.app.boards;

const getList = (
  state: RootState,
  props: TasksPageProps
): appModels.List | undefined =>
  get(state, `app.lists.${props.match.params.idList}`);

const getLists = (state: RootState) => state.app.lists;

const getAllTasks = (state: RootState) => state.app.tasks;

export const getBoard = createSelector(
  getBoards,
  getLists,
  getList,
  (boards, lists, list) => {
    if (!boards || !lists || !list) {
      return undefined;
    }

    const board = { ...boards[list.shortIdBoard] } as Board;

    if (!board) {
      throw new Error(`Could not find board: ${list.idBoard}`);
    } else if (!board.listIds) {
      return undefined;
    }

    board.lists = board.listIds.map(({ shortId }) => lists[shortId]);

    return board;
  }
);

export const getTasks = createSelector(
  getAllTasks,
  getList,
  (tasks, list): Tasks => {
    if (!list || !list.tasks) {
      return;
    }

    const cards = list.tasks
      .map(idTask => tasks[idTask])
      .filter(task => task.checklists !== undefined) as appModels.TaskCard[];

    return cards.map(task => ({
      ...task,
      checklists: task.checklists.map(checklist => ({
        ...checklist,
        checkItems: checklist.checkItems.map(
          checkItemId => tasks[checkItemId] as appModels.TaskCheckItem
        ),
      })),
    }));
  }
);
