import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';
import longBreakTimerEnded from './longBreakTimerEnded';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {}
) {
  return longBreakTimerEnded.apply(this, [getVariable, meta]);
}
