import { Location } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { bindActionCreators, Dispatch } from 'redux';
import 'what-input';
import { appActions, appModels } from '..';
import storage from '../../../global/helpers/storage';
import wakeLock from '../../../global/helpers/wakeLock';
import BoardsPage from '../../../pages/boards';
import { ServicesPage } from '../../../pages/boardsAndLists';
import { BreakPage } from '../../../pages/break';
import SettingsPage from '../../../pages/settings';
import TaskPage from '../../../pages/task';
import TasksPage from '../../../pages/tasks';
import { RootAction, RootState } from '../../../store/types';
import './app.scss';
import Menu from './Menu';

export type AppProps = AppOwnProps & AppStateProps & AppDispatchProps;

interface AppOwnProps {
  location: Location;
}

interface AppStateProps {
  readonly bootstrapped: appModels.Bootstrapped;
  readonly currentList: appModels.CurrentList;
  readonly currentTask: appModels.CurrentTask;
  readonly menuOpen: appModels.MenuOpen;
  readonly pomelloUser?: appModels.PomelloUser;
  readonly settings: appModels.Settings;
  readonly timerActive: boolean;
  readonly timerTime?: number;
  readonly timerType?: string;
}

interface AppDispatchProps {
  readonly hideMenu: () => void;
  readonly incrementPomodoros: () => void;
  readonly resetPomodoros: () => void;
}

const mapStateToProps = (state: RootState) => ({
  bootstrapped: state.app.bootstrapped,
  currentList: state.app.currentList,
  currentTask: state.app.currentTask,
  menuOpen: state.app.menuOpen,
  pomelloUser: state.app.pomelloUser,
  settings: state.app.settings,
  timerActive: state.timer.active,
  timerTime: state.timer.time,
  timerType: state.timer.type,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      hideMenu: appActions.hideMenu,
      incrementPomodoros: appActions.incrementPomodoros,
      resetPomodoros: appActions.resetPomodoros,
    },
    dispatch
  );

export class App extends Component<AppProps> {
  get defaultRoute() {
    const currentList = storage.get('currentList');
    return currentList ? `/tasks/${currentList}` : '/boards';
  }

  componentDidUpdate = (prevProps: AppProps) => {
    if (
      this.props.currentList &&
      this.props.currentList !== prevProps.currentList
    ) {
      this.handleCurrentListChange();
    } else if (this.props.timerActive && !prevProps.timerActive) {
      this.handleTimerStart();
    } else if (
      !this.props.timerActive &&
      prevProps.timerActive &&
      prevProps.timerTime === 0
    ) {
      this.handleTimerEnd(prevProps.timerType);
    } else if (
      !this.props.timerActive &&
      prevProps.timerActive &&
      prevProps.timerTime !== 0
    ) {
      this.handleTimerDestroy(prevProps.timerType);
    }
  };

  disableWakeLockIfNecessary(timerType?: string) {
    const {
      currentTask,
      settings: { autoStartBreaks, autoStartTasks },
    } = this.props;

    if (
      (timerType === 'task' && autoStartBreaks) ||
      ((timerType === 'short-break' || timerType === 'long-break') &&
        autoStartTasks &&
        currentTask)
    ) {
      return;
    }

    wakeLock.disableIfNecessary();
  }

  handleCurrentListChange = () => {
    storage.set('currentList', this.props.currentList);
  };

  handleTaskTimerStart = () => {
    const {
      resetPomodoros,
      settings: { longBreakTime },
    } = this.props;
    const breakStartTime = storage.get('breakStartTime');

    if (!isNaN(breakStartTime)) {
      const deltaTime = (Date.now() - breakStartTime) / 1000;

      if (deltaTime > longBreakTime) {
        resetPomodoros();
      }
    }
  };

  handleTimerDestroy = (timerType?: string) => {
    this.disableWakeLockIfNecessary(timerType);
  };

  handleTimerEnd = (timerType?: string) => {
    this.disableWakeLockIfNecessary(timerType);

    if (timerType === 'task') {
      this.props.incrementPomodoros();
    }
  };

  handleTimerStart = () => {
    switch (this.props.timerType) {
      case 'task':
        return this.handleTaskTimerStart();
      default:
        break;
    }
  };

  render() {
    const { bootstrapped, hideMenu, menuOpen, pomelloUser } = this.props;

    return (
      <div className="app">
        <div className="app__contents">
          <Switch>
            <Redirect exact={true} from="/" to={this.defaultRoute} />
            <Route path="/boards" component={BoardsPage} />
            <Route path="/services" component={ServicesPage} />
            <Route path="/settings" component={SettingsPage} />
            <Route path="/tasks/:idList" component={TasksPage} />
            <Route path="/task/:idCard/:idChecklist?" component={TaskPage} />
            <Route path="/:breakType(short|long)-break" component={BreakPage} />
          </Switch>
        </div>
        <CSSTransition
          in={menuOpen}
          classNames="menu"
          timeout={300}
          unmountOnExit={true}
        >
          <Menu
            handleMenuHide={hideMenu}
            bootstrapped={bootstrapped}
            profile={pomelloUser}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
