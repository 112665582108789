import produce from 'immer';
import { getType } from 'typesafe-actions';
import { ToastAction, toastModels } from '.';
import * as toast from './actions';

type ToastState = Readonly<{
  current: toastModels.Toast | null;
  isProcessingQueue: boolean;
  queue: toastModels.Toast[];
}>;

export const toastDefaultState = {
  current: null,
  isProcessingQueue: false,
  queue: [],
};

export default (state: ToastState = toastDefaultState, action: ToastAction) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(toast.endQueue): {
        draft.isProcessingQueue = false;
        return;
      }

      case getType(toast.processToastQueue): {
        draft.current = draft.queue.splice(0, 1)[0];
        draft.isProcessingQueue = true;
        return;
      }

      case getType(toast.queueToast): {
        draft.queue.push(action.payload);
        return;
      }

      case getType(toast.removeToast): {
        draft.current = null;
        return;
      }
    }
  });
