import { has } from 'lodash';
import React, { ChangeEvent, SFC } from 'react';
import Modal from '../../features/modal';
import { ServicePreferences } from './ServicesPage';

interface PreferencesModalProps {
  readonly handleClose: () => void;
  readonly handlePrefChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  readonly heading: string;
  readonly idBoardOrList: string;
  readonly labels: Array<{
    id: string;
    label: string;
  }>;
  readonly preferences: ServicePreferences;
  readonly type: string;
}

const getPreference = (
  preferences: ServicePreferences,
  type: string,
  setting: string,
  idBoardOrList: string
) => {
  const preference = preferences[setting as keyof ServicePreferences];

  if (has(preference, `${type}Includes.${idBoardOrList}`)) {
    return 'yes';
  } else if (has(preference, `${type}Excludes.${idBoardOrList}`)) {
    return 'no';
  } else {
    return 'default';
  }
};

export const PreferencesModal: SFC<PreferencesModalProps> = ({
  handleClose,
  handlePrefChange,
  heading,
  idBoardOrList,
  labels,
  preferences,
  type,
}) => (
  <Modal buttons={[{ label: 'Close' }]} handleClose={handleClose}>
    <div className="content">
      <h6>{heading}</h6>
    </div>
    {labels.map(({ id: setting, label }) => {
      const preference = getPreference(
        preferences,
        type,
        setting,
        idBoardOrList
      );

      return (
        <div className="field" key={setting}>
          <label htmlFor={`${idBoardOrList}-${setting}`} className="label">
            {label}
          </label>
          <div className="select">
            <select
              data-current-value={preference}
              id={`${type}-${idBoardOrList}-${setting}`}
              onChange={handlePrefChange}
              value={preference}
            >
              <option value="defaut">Default</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
      );
    })}
  </Modal>
);
