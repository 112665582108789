import axios, { AxiosInstance } from 'axios';
import EventEmitter from 'events';
import * as handlers from './handlers';
import storage from '../../helpers/storage';

type HandlersType = typeof handlers;

type ApiHandlers<T> = { [K in keyof T]: T[K] };

export interface TrelloService extends ApiHandlers<HandlersType> {
  api: AxiosInstance;
}

export class TrelloService extends EventEmitter {
  static baseURL = 'https://trello.com';

  static apiURL = 'https://api.trello.com/1';

  get authUrl() {
    const params = [
      'expiration=never',
      'name=Pomello (Web)',
      'scope=read,write',
      'response_type=token',
      `key=${process.env.REACT_APP_TRELLO_API_KEY}`,
      'callback_method=fragment',
      `return_url=${window.location.origin}/connect`,
    ];

    return `${TrelloService.baseURL}/1/authorize?${params.join('&')}`;
  }

  constructor() {
    super();

    this.setUpApi();

    if (storage.has('trelloToken')) {
      const token = storage.get('trelloToken');
      this.storeApiToken(token);
    }

    storage.on('change', (key: string, value: any, prevValue: any) => {
      if (key === 'trelloToken' && value && !prevValue) {
        this.storeApiToken(value);
      }
    });

    (Object.keys(handlers) as Array<keyof HandlersType>).forEach(handler => {
      this[handler] = handlers[handler];
    });
  }

  setUpApi() {
    this.api = axios.create({
      baseURL: TrelloService.apiURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          const { status } = error.response;

          if (status === 401) {
            this.emit('error', 'auth');
          } else if (status === 500) {
            this.emit('error', 'server');
          }
        } else if (error.request) {
          this.emit('error', 'network');
        }

        return Promise.reject(error);
      }
    );
  }

  storeApiToken(token: string) {
    this.api.defaults.params = {
      key: process.env.REACT_APP_TRELLO_API_KEY,
      token,
    };
  }
}

const trelloService = new TrelloService();

export default trelloService;
