import thunk from 'redux-thunk';
import { compose, createStore, applyMiddleware } from 'redux';
import reducers from './reducers';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

function configureStore(initialState?: object) {
  const middlewares = [thunk];

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(reducers, initialState!, enhancers);
}

const store = configureStore();

export default store;
