import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../../store/types';

const getCurrentTaskId = (state: RootState) => state.app.currentTask;

const getTasks = (state: RootState) => state.app.tasks;

export const getCurrentTask = createSelector(
  getCurrentTaskId,
  getTasks,
  (currentTaskId, tasks) => {
    if (!currentTaskId || !tasks) {
      return null;
    }

    return get(tasks, currentTaskId, null);
  }
);
