import classnames from 'classnames';
import produce from 'immer';
import React, { Component, Fragment, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { appActions, appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import MiniClock from '../../features/miniClock';
import trelloService from '../../global/services/trello';
import { RootAction, RootState } from '../../store/types';
import ListsPlaceholder from './ListsPlaceholder';
import { Board, getBoard, getTasks, Task, Tasks } from './selectors';
import './tasksPage.scss';
import TasksPlaceholder from './TasksPlaceholder';

type RouteParams = {
  idList: string;
};

export type TasksPageProps = TasksPageOwnProps &
  TasksPageStateProps &
  TasksPageDispatchProps;

interface TasksPageOwnProps extends RouteComponentProps<RouteParams> {}

interface TasksPageStateProps {
  readonly autoStartBreaks: appModels.Settings['autoStartBreaks'];
  readonly board?: Board;
  readonly bootstrapped: appModels.Bootstrapped;
  readonly nextBreak: appModels.NextBreak;
  readonly tasks?: Tasks;
  readonly taskTimerActive: boolean;
  readonly timerTime?: number;
}

interface TasksPageDispatchProps {
  readonly storeCurrentList: (list: appModels.List['id']) => void;
  readonly storeListTasks: (payload: appModels.ListTasksPayload) => void;
  readonly storeTasks: (tasks: appModels.Tasks) => void;
}

export interface TasksPageState {
  readonly collapsedTasks: {
    [key: string]: true;
  };
}

const mapStateToProps = (state: RootState, props: TasksPageProps) => ({
  autoStartBreaks: state.app.settings.autoStartBreaks,
  board: getBoard(state, props),
  bootstrapped: state.app.bootstrapped,
  nextBreak: state.app.nextBreak,
  tasks: getTasks(state, props),
  taskTimerActive: state.timer.type === 'task' && state.timer.active,
  timerTime: state.timer.time,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      storeCurrentList: appActions.setCurrentList,
      storeListTasks: appActions.storeListTasks,
      storeTasks: appActions.storeTasks,
    },
    dispatch
  );

export class TasksPage extends Component<TasksPageProps, TasksPageState> {
  state: TasksPageState = {
    collapsedTasks: {},
  };

  componentDidMount() {
    if (this.props.bootstrapped) {
      this.handleBootstrap();
    }
  }

  componentDidUpdate(prevProps: TasksPageProps) {
    if (this.props.bootstrapped && !prevProps.bootstrapped) {
      this.handleBootstrap();
    } else if (
      this.props.match.params.idList !== prevProps.match.params.idList
    ) {
      this.handleBootstrap();
    }

    if (
      !this.props.taskTimerActive &&
      prevProps.taskTimerActive &&
      prevProps.timerTime === 0
    ) {
      this.handleTaskTimerEnd();
    }
  }

  handleBootstrap = () => {
    const {
      match: {
        params: { idList },
      },
      storeCurrentList,
      tasks,
    } = this.props;

    storeCurrentList(idList);

    // This timeout is a hacky way of avoiding rendering issues after a task
    // has been moved to another list. When the user finishes a task early and
    // selects a list, they are immediately routed to the /tasks page and there
    // isn't enough time for Trello to reflect the move. So when it's fetching
    // the tasks, it will still have the old task listed in the current list.
    // By adding a little delay, we give sometime for Trello to make the change.
    const timeout = !tasks ? 0 : 500;
    setTimeout(this.fetchTasks, timeout);
  };

  handleCollapseClick = (id: string, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    this.setState(
      produce<TasksPageState>(draft => {
        if (!draft.collapsedTasks[id]) {
          draft.collapsedTasks[id] = true;
        } else {
          delete draft.collapsedTasks[id];
        }
      })
    );
  };

  handleTaskTimerEnd = () => {
    const { autoStartBreaks, history, nextBreak } = this.props;

    history.push(`/${nextBreak}-break`, { autoStart: autoStartBreaks });
  };

  hasChecklists = (task: Task) => task.checklists && task.checklists.length > 0;

  isCollapsed = ({ id }: Task) => this.state.collapsedTasks[id] === true;

  fetchTasks = async () => {
    const {
      match: {
        params: { idList },
      },
      storeListTasks,
      storeTasks,
    } = this.props;

    const response = await trelloService.fetchCardsAndChecklists(idList);

    if (response.success) {
      storeTasks(response.data.tasks);
      storeListTasks({
        idList,
        listTasks: response.data.listsTasks,
      });
    }
  };

  public render() {
    const { board, bootstrapped, tasks, taskTimerActive } = this.props;

    return (
      <>
        <AppBar returnRoute="/boards" title={board && board.name} />
        <nav className="secondary-bar tasks-page__lists">
          {bootstrapped && board ? (
            board.lists.map(list => (
              <NavLink
                className="tasks-page__list"
                data-list={list.name}
                key={list.id}
                to={`/tasks/${list.shortId}`}
              >
                {list.name}
              </NavLink>
            ))
          ) : (
            <ListsPlaceholder />
          )}
        </nav>
        <div className="list">
          {bootstrapped && tasks ? (
            tasks.map(task => (
              <Fragment key={task.id}>
                <Link
                  to={`/task/${task.shortId}`}
                  key={task.id}
                  className="list__item"
                >
                  <span className="list__content">{task.name}</span>
                  {this.hasChecklists(task) && (
                    <span className="list__actions">
                      <button
                        className={classnames('icon', 'chevron', {
                          'is-rotated-180': this.isCollapsed(task),
                        })}
                        onClick={this.handleCollapseClick.bind(this, task.id)}
                      />
                    </span>
                  )}
                </Link>
                {this.hasChecklists(task) && !this.isCollapsed(task) && (
                  <div className="list__child">
                    {task.checklists.map(checklist => (
                      <Fragment key={checklist.id}>
                        <div className="list__child-heading">
                          {checklist.name}
                        </div>
                        {checklist.checkItems.map(checkItem => (
                          <Link
                            to={`/task/${task.shortId}/${checkItem.shortId}`}
                            key={checkItem.id}
                            className="list__child-item"
                          >
                            {checkItem.name}
                          </Link>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                )}
              </Fragment>
            ))
          ) : (
            <TasksPlaceholder />
          )}
        </div>
        {taskTimerActive && <MiniClock />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksPage);
