export const humanTime = (time: number) => {
  if (time < 60) {
    return `${time} seconds`;
  }

  const minutes = Math.floor(time / 60);
  let seconds = (time % 60).toString();

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
};
