import produce from 'immer';
import { getType } from 'typesafe-actions';
import { OvertimeAction, overtimeActions, overtimeModels } from '.';
import { appModels } from '../app';
import { humanTime } from './helpers';

type OvertimeState = Readonly<{
  active: overtimeModels.Active;
  eventId: overtimeModels.EventId;
  humanTime: overtimeModels.HumanTime;
  taskId: appModels.Task['id'] | null;
  taskIdCard: appModels.Task['idCard'] | null;
  time: overtimeModels.Time;
  timestamp: overtimeModels.Timestamp | null;
  type: overtimeModels.Type | null;
}>;

const overtimeDefaultState = {
  active: false,
  eventId: null,
  humanTime: '',
  taskId: null,
  taskIdCard: null,
  time: 0,
  timestamp: null,
  type: null,
};

export default (
  state: OvertimeState = overtimeDefaultState,
  action: OvertimeAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(overtimeActions.createOvertime): {
        return {
          ...draft,
          ...action.payload,
          active: true,
          humanTime: humanTime(action.payload.time),
        };
      }

      case getType(overtimeActions.destroyOvertime): {
        return { ...overtimeDefaultState };
      }

      case getType(overtimeActions.incrementTime): {
        draft.time += 1;
        draft.humanTime = humanTime(draft.time);
        return;
      }
    }
  });
