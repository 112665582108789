import produce from 'immer';
import { omit } from 'lodash';
import { getType } from 'typesafe-actions';
import { TimerAction, timerActions, timerModels } from '.';
import { humanTime } from './helpers';

type TimerState = Readonly<{
  active: boolean;
  endSoundSrc?: string | false;
  endSoundVol?: number | false;
  humanTime?: string;
  markers: timerModels.Markers;
  paused: boolean;
  startSoundSrc?: string | false;
  startSoundVol?: number | false;
  startTime?: number;
  tickSoundSrc?: string | false;
  tickSoundVol?: number | false;
  time?: timerModels.Time;
  type?: string;
}>;

export const timerDefaultState = {
  active: false,
  markers: {},
  paused: false,
};

export default (state: TimerState = timerDefaultState, action: TimerAction) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(timerActions.createTimer): {
        const { startTime } = action.payload;

        return {
          ...draft,
          ...action.payload,
          time: startTime,
          humanTime: humanTime(startTime),
        };
      }

      case getType(timerActions.decrementTime): {
        if (draft.time && draft.humanTime) {
          draft.time -= 1;
          draft.humanTime = humanTime(draft.time);
        }
        return;
      }

      case getType(timerActions.destroyTimer): {
        return timerDefaultState as TimerState;
      }

      case getType(timerActions.pauseTimer): {
        if (draft.active) {
          draft.paused = true;
        }
        return;
      }

      case getType(timerActions.resumeTimer): {
        if (draft.paused) {
          draft.paused = false;
        }
        return;
      }

      case getType(timerActions.setMarker): {
        const time = draft.time;

        if (time) {
          draft.markers = {
            ...draft.markers,
            [action.payload.id]: {
              time,
              timestamp: action.payload.timestamp,
            },
          };
        }

        return;
      }

      case getType(timerActions.startTimer): {
        draft.active = true;
        return;
      }

      case getType(timerActions.unsetMarker): {
        draft.markers = omit(draft.markers, action.payload);
      }
    }
  });
