import { encodeHex } from 'base-62.js';
import { chain } from 'lodash';
import { TrelloService } from '..';
import { appModels } from '../../../../features/app';

type FetchBoardsAndListsSuccess = {
  success: true;
  data: {
    boards: appModels.Boards;
    lists: appModels.Lists;
  };
};

type FetchBoardsAndListsFailure = {
  success: false;
};

const getBoardsFromResponse = (boards: any) =>
  chain(boards)
    .map((board: any) => ({
      backgroundColor: board.prefs.backgroundColor,
      backgroundImage: board.prefs.backgroundImageScaled
        ? board.prefs.backgroundImageScaled[0].url
        : null,
      dateLastView: new Date(board.dateLastView).getTime(),
      id: board.id,
      listIds: board.lists.map((list: any) => ({
        id: list.id,
        shortId: encodeHex(list.id),
      })),
      name: board.name,
      shortId: encodeHex(board.id),
    }))
    .keyBy('shortId')
    .value();

const getListsFromReponse = (boards: any) =>
  chain(boards)
    .map(board =>
      board.lists.map(({ id, idBoard, name }: any) => ({
        id,
        idBoard,
        name,
        shortId: encodeHex(id),
        shortIdBoard: encodeHex(idBoard),
      }))
    )
    .flatten()
    .keyBy('shortId')
    .value();

export default async function(
  this: TrelloService
): Promise<FetchBoardsAndListsSuccess | FetchBoardsAndListsFailure> {
  try {
    const response = await this.api.get('/members/me/boards', {
      params: {
        filter: 'open',
        lists: 'open',
      },
    });

    const boards = getBoardsFromResponse(response.data);
    const lists = getListsFromReponse(response.data);

    return {
      success: true,
      data: { boards, lists },
    };
  } catch (error) {
    return { success: false };
  }
}
