import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {}
) {
  const overtime = getVariable('overtime');

  if (!overtime || !overtime.eventId || !overtime.taskId) {
    return;
  }

  const event = this.getEvent(overtime.eventId);

  if (!event) {
    return this.queueJob(overtime.eventId, 'overtimeEnded', [
      getVariable,
      meta,
    ]);
  }

  return this.logEvent({
    duration: overtime.time,
    parent_id: event.id,
    parent_service_id: overtime.taskIdCard,
    service_id: overtime.taskId,
    start_time: overtime.timestamp,
    type: `over_${overtime.type}`,
  });
}
