import { History } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { appActions, appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import Clock from '../../features/clock';
import { timerActions, timerModels } from '../../features/timer';
import { triggersEmitter } from '../../features/triggers';
import checkIcon from '../../global/assets/check.svg';
import noteIcon from '../../global/assets/note.svg';
import switchIcon from '../../global/assets/switch.svg';
import pauseResumeIcon from '../../global/assets/timer.svg';
import voidIcon from '../../global/assets/void.svg';
import getTaskName from '../../global/helpers/getTaskName';
import getSoundSrc from '../../global/helpers/sounds';
import timestamp from '../../global/helpers/timestamp';
import wakeLock from '../../global/helpers/wakeLock';
import { RootAction, RootState } from '../../store/types';

export type TaskClockProps = TaskClockOwnProps &
  TaskClockStateProps &
  TaskClockDispatchProps;

export interface TaskClockOwnProps {
  readonly autoStart: boolean;
  readonly board?: appModels.Board;
  readonly bootstrapped: appModels.Bootstrapped;
  readonly handleNoteModalToggle: () => void;
  readonly handleTaskFinish: () => void;
  readonly history: History;
  readonly routeToBreak: (autoStart?: boolean, isContinuing?: boolean) => void;
  readonly task?: appModels.Task;
}

interface TaskClockStateProps {
  readonly endSoundSrc: string;
  readonly endSoundVol: number;
  readonly gracePeriod: appModels.GracePeriod | null;
  readonly showQuitTaskWarning: appModels.Settings['warnBeforeTaskCancel'];
  readonly startSoundSrc: string;
  readonly startSoundVol: number;
  readonly taskStartTime: number;
  readonly tickSoundSrc: string;
  readonly tickSoundVol: number;
  readonly timerActive: boolean;
  readonly timerPaused: boolean;
  readonly timerReady: boolean;
  readonly timerTime?: number;
}

interface TaskClockDispatchProps {
  readonly addTimerMarker: (id: string) => void;
  readonly createTimer: (settings: timerModels.Settings) => void;
  readonly destroyTimer: () => void;
  readonly pauseTimer: () => void;
  readonly resumeTimer: () => void;
  readonly setCurrentList: (list: appModels.List['shortId']) => void;
  readonly setCurrentTask: (
    payload: {
      id: string;
      startTime: { time: timerModels.Time; timestamp: number };
    }
  ) => void;
  readonly startGracePeriod: () => void;
  readonly unsetCurrentTask: () => void;
  readonly unsetTimerMarker: (id: string) => void;
}

interface TaskClockState {
  disableWarningPrompt: boolean;
}

const mapStateToProps = (state: RootState, props: TaskClockOwnProps) => ({
  endSoundSrc: getSoundSrc(state.app.settings.taskTimerEndSound),
  endSoundVol: state.app.settings.taskTimerEndVol,
  gracePeriod: state.app.betweenTasksGracePeriod,
  showQuitTaskWarning: state.app.settings.warnBeforeTaskCancel,
  startSoundSrc: getSoundSrc(state.app.settings.taskTimerStartSound),
  startSoundVol: state.app.settings.taskTimerStartVol,
  taskStartTime: state.app.settings.taskTime,
  tickSoundSrc: getSoundSrc(state.app.settings.taskTimerTickSound),
  tickSoundVol: state.app.settings.taskTimerTickVol,
  timerActive: state.timer.active && state.timer.type === 'task',
  timerPaused: state.timer.paused,
  timerReady: props.bootstrapped && (props.board && props.task) !== undefined,
  timerTime: state.timer.time,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      addTimerMarker: timerActions.addMarker,
      createTimer: timerActions.createTimer,
      destroyTimer: timerActions.destroyTimer,
      pauseTimer: timerActions.pauseTimer,
      resumeTimer: timerActions.resumeTimer,
      setCurrentList: appActions.setCurrentList,
      setCurrentTask: appActions.setCurrentTask,
      startGracePeriod: appActions.startGracePeriod,
      unsetCurrentTask: appActions.unsetCurrentTask,
      unsetTimerMarker: timerActions.unsetMarker,
    },
    dispatch
  );

export class TaskClock extends Component<TaskClockProps, TaskClockState> {
  state: TaskClockState = {
    disableWarningPrompt: false,
  };

  componentDidMount() {
    const { timerActive, timerReady } = this.props;

    if (timerActive) {
      this.handleTimerStart();
    } else if (timerReady) {
      this.handleTimerReady();
    }
  }

  componentWillUpdate(nextProps: TaskClockProps) {
    if (!this.props.timerActive && nextProps.timerActive) {
      this.handleTimerStart();
    }
  }

  componentDidUpdate(prevProps: TaskClockProps) {
    if (this.props.timerReady && !prevProps.timerReady) {
      this.handleTimerReady();
    } else if (this.props.timerPaused && !prevProps.timerPaused) {
      this.handleTimerPaused();
    } else if (!this.props.timerPaused && prevProps.timerPaused) {
      this.handleTimerResumed();
    }
  }

  componentWillUnmount = () => {
    const { destroyTimer, timerActive } = this.props;
    const { disableWarningPrompt } = this.state;

    if (timerActive && !disableWarningPrompt) {
      triggersEmitter.emit('event', 'timerDestroyed');

      destroyTimer();

      wakeLock.disableIfNecessary();
    }
  };

  disableWarningPrompt = () =>
    new Promise(resolve => {
      this.setState({ disableWarningPrompt: true }, resolve);
    });

  handleTimerReady = () => {
    const { setCurrentList, task } = this.props;

    setCurrentList(task!.shortIdList);

    this.createTimer();
  };

  handleTimerStart = () => {
    const {
      gracePeriod,
      setCurrentTask,
      addTimerMarker,
      task,
      timerTime,
    } = this.props;

    if (!task) {
      throw new Error('Unable to get task');
    }

    if (!timerTime) {
      throw new Error('Unable to get timer time');
    }

    setCurrentTask({
      id: task.shortId,
      startTime: gracePeriod || {
        time: timerTime,
        timestamp: timestamp(),
      },
    });

    addTimerMarker('taskStart');
  };

  handleTimerPaused = () => {
    this.props.addTimerMarker('taskPause');
  };

  handleTimerResumed = () => {
    this.props.unsetTimerMarker('taskPause');
  };

  handleNoteAdd = () => {
    this.props.handleNoteModalToggle();
  };

  handleTaskFinish = async () => {
    const { handleTaskFinish, unsetTimerMarker } = this.props;

    triggersEmitter.emit('event', 'taskFinished');

    await this.disableWarningPrompt();

    this.startGracePeriod();

    handleTaskFinish();

    unsetTimerMarker('taskStart');
  };

  handleTaskSwitch = async () => {
    const { history, unsetCurrentTask, unsetTimerMarker } = this.props;

    triggersEmitter.emit('event', 'taskSwitched');

    await this.disableWarningPrompt();

    this.startGracePeriod();

    history.push('/');

    unsetCurrentTask();

    unsetTimerMarker('taskStart');
  };

  handleTaskVoid = async () => {
    const { destroyTimer, routeToBreak } = this.props;

    triggersEmitter.emit('event', 'taskVoided');

    await this.disableWarningPrompt();

    destroyTimer();

    routeToBreak(true, true);
  };

  createTimer = () => {
    const { createTimer, timerActive } = this.props;

    if (timerActive) {
      return;
    }

    createTimer({
      endSoundSrc: this.props.endSoundSrc,
      endSoundVol: this.props.endSoundVol,
      startSoundSrc: this.props.startSoundSrc,
      startSoundVol: this.props.startSoundVol,
      startTime: this.props.taskStartTime,
      tickSoundSrc: this.props.tickSoundSrc,
      tickSoundVol: this.props.tickSoundVol,
      type: 'task',
    });
  };

  startGracePeriod = () => {
    this.props.startGracePeriod();
  };

  render() {
    const {
      autoStart,
      board,
      pauseTimer,
      resumeTimer,
      showQuitTaskWarning,
      task,
      timerActive,
      timerPaused,
      timerReady,
    } = this.props;

    const { disableWarningPrompt } = this.state;

    return (
      <>
        <AppBar
          returnRoute={task ? `/tasks/${task.shortIdList}` : '/boards'}
          transparent={true}
        />
        <Clock
          actions={[
            {
              id: 'pauseResumeTimer',
              icon: pauseResumeIcon,
              label: !timerPaused ? 'Pause timer' : 'Resume timer',
              callback: !timerPaused ? pauseTimer : resumeTimer,
            },
            {
              id: 'taskComplete',
              icon: checkIcon,
              label: 'Mark task complete',
              callback: this.handleTaskFinish,
            },
            {
              id: 'switchTask',
              icon: switchIcon,
              label: 'Switch task',
              callback: this.handleTaskSwitch,
            },
            {
              id: 'voidTask',
              icon: voidIcon,
              label: 'Void task',
              callback: this.handleTaskVoid,
            },
            {
              id: 'addNote',
              icon: noteIcon,
              label: 'Add note',
              callback: this.handleNoteAdd,
            },
          ]}
          autostart={autoStart}
          eyebrow={board ? board.name : ''}
          heading={task ? getTaskName(task.name) : ''}
          loading={!timerReady}
          themeColor="#f34235"
          type="task"
        />
        {showQuitTaskWarning && (
          <Prompt
            when={timerActive && !disableWarningPrompt}
            message={'This will quit your active task. Are you sure?'}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskClock);
