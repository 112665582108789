import { TrelloService } from '..';
import { appModels } from '../../../../features/app';

type FetchUserSuccess = {
  success: true;
  data: appModels.TrelloUser;
};

type FetchUserFailure = {
  success: false;
};

export default async function(
  this: TrelloService
): Promise<FetchUserSuccess | FetchUserFailure> {
  try {
    const response = await this.api.get('/members/me');

    return {
      success: true,
      data: {
        id: response.data.id,
      },
    };
  } catch (error) {
    return { success: false };
  }
}
