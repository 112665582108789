import produce from 'immer';
import { set } from 'lodash';
import { getType } from 'typesafe-actions';
import { AppAction, appActions, appModels } from '.';
import storage from '../../global/helpers/storage';
import { timerModels } from '../timer';
import settings from './settings.json';

type AppState = Readonly<{
  betweenTasksGracePeriod: appModels.GracePeriod | null;
  boards?: appModels.Boards;
  bootstrapped: appModels.Bootstrapped;
  currentList: appModels.CurrentList;
  currentTask: appModels.CurrentTask;
  currentTaskStartTime: {
    time: timerModels.Time;
    timestamp: number;
  } | null;
  lists?: appModels.Lists;
  menuOpen: appModels.MenuOpen;
  nextBreak: appModels.NextBreak;
  pomelloUser?: appModels.PomelloUser;
  pomodoros: appModels.Pomodoros;
  prevPomodoros: appModels.Pomodoros;
  settings: appModels.Settings;
  status: appModels.Status;
  tasks: appModels.Tasks;
  trelloUser?: appModels.TrelloUser;
}>;

const appDefaultSettings = { ...settings, timestamp: null };

export const appDefaultState = {
  betweenTasksGracePeriod: null,
  bootstrapped: false,
  currentList: storage.get('currentList') || null,
  currentTask: null,
  currentTaskStartTime: null,
  menuOpen: false,
  nextBreak: 'short',
  pomodoros: storage.get('pomodoros') || 0,
  prevPomodoros: storage.get('pomodoros') || 0,
  settings: appDefaultSettings,
  status: 'initializing',
  tasks: {},
};

export default (state: AppState = appDefaultState, action: AppAction) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(appActions.hideMenu): {
        draft.menuOpen = false;
      }

      case getType(appActions.incrementPomodoros): {
        const { pomodoroSet } = draft.settings;

        draft.prevPomodoros = draft.pomodoros;
        draft.pomodoros += 1;
        draft.nextBreak = draft.pomodoros >= pomodoroSet ? 'long' : 'short';

        if (draft.nextBreak === 'long') {
          draft.pomodoros = 0;
        }

        return;
      }

      case getType(appActions.moveTaskToList): {
        const { shortIdTask, shortIdList } = action.payload;

        const task = draft.tasks[shortIdTask];
        const movePos = draft.settings.completedTaskPosition;

        if (draft.lists) {
          const prevList = draft.lists[task.shortIdList];
          const curList = draft.lists[shortIdList];

          if (prevList.tasks) {
            const taskIndex = prevList.tasks.findIndex(
              listTask => listTask === shortIdTask
            );

            if (taskIndex !== -1) {
              prevList.tasks.splice(taskIndex, 1);
              task.shortIdList = shortIdList;
            }

            if (!curList.tasks) {
              curList.tasks = [];
            }

            if (movePos === 'top') {
              curList.tasks.unshift(shortIdTask);
            } else if (movePos === 'bottom') {
              curList.tasks.push(shortIdTask);
            }
          }
        }

        return;
      }

      case getType(appActions.removePomelloUser): {
        delete draft.pomelloUser;
        return;
      }

      case getType(appActions.removeTaskCheckItem): {
        const idTask = action.payload;
        const checkItem = draft.tasks[idTask];
        const checklists = draft.tasks[checkItem.shortIdCard!].checklists!;

        let itemIndex: number | null = null;
        const checklistIndex = checklists.findIndex(({ checkItems }) => {
          itemIndex = checkItems.findIndex(item => item === action.payload);
          return itemIndex !== -1;
        });

        if (itemIndex !== null) {
          checklists[checklistIndex].checkItems.splice(itemIndex, 1);

          // If the checklist is now empty, we should remove that too
          if (checklists[checklistIndex].checkItems.length === 0) {
            checklists.splice(checklistIndex, 1);
          }
        }

        if (checklists.length === 0) {
          delete draft.tasks[checkItem.shortIdCard!].checklists;
        }

        delete draft.tasks[idTask];

        return;
      }

      case getType(appActions.removeTrelloUser): {
        delete draft.trelloUser;
        return;
      }

      case getType(appActions.resetPomodoros): {
        draft.pomodoros = 0;
        return;
      }

      case getType(appActions.resetSettings): {
        draft.settings = appDefaultSettings;
        return;
      }

      case getType(appActions.setCurrentList): {
        if (!draft.lists || !draft.lists[action.payload]) {
          return;
        }

        draft.currentList = action.payload;

        return;
      }

      case getType(appActions.setCurrentTask): {
        draft.currentTask = action.payload.id;
        draft.currentTaskStartTime = action.payload.startTime;
        return;
      }

      case getType(appActions.setGracePeriod): {
        draft.betweenTasksGracePeriod = action.payload;
        return;
      }

      case getType(appActions.setSetting): {
        const { name, value } = action.payload;

        const type = typeof draft.settings[name];

        let parsedValue = value;

        if (type === 'number') {
          parsedValue = Number(value);
        } else if (type === 'boolean') {
          parsedValue = Boolean(value);
        } else if (type === 'string') {
          parsedValue = String(value);
        }

        draft.settings[name] = parsedValue;

        return;
      }

      case getType(appActions.setStatus): {
        draft.status = action.payload;
        draft.bootstrapped = action.payload === 'bootstrapped';
        return;
      }

      case getType(appActions.showMenu): {
        draft.menuOpen = true;
        return;
      }

      case getType(appActions.storeBoards): {
        draft.boards = action.payload;
        return;
      }

      case getType(appActions.storeListTasks): {
        set(
          draft,
          `lists.${action.payload.idList}.tasks`,
          action.payload.listTasks
        );
        return;
      }

      case getType(appActions.storeLists): {
        draft.lists = action.payload;
        return;
      }

      case getType(appActions.storePomelloUser): {
        draft.pomelloUser = action.payload;
        return;
      }

      case getType(appActions.storeSettings): {
        draft.settings = {
          ...draft.settings,
          ...action.payload,
        };

        return;
      }

      case getType(appActions.storeTasks): {
        draft.tasks = { ...draft.tasks, ...action.payload };
        return;
      }

      case getType(appActions.storeTrelloUser): {
        draft.trelloUser = action.payload;
        return;
      }

      case getType(appActions.unsetCurrentList): {
        draft.currentList = null;
        return;
      }

      case getType(appActions.unsetCurrentTask): {
        draft.currentTask = null;
        draft.currentTaskStartTime = null;
        return;
      }

      case getType(appActions.unsetGracePeriod): {
        draft.betweenTasksGracePeriod = null;
        return;
      }
    }
  });
