import classnames from 'classnames';
import React, { ChangeEvent, Component, createRef, FormEvent } from 'react';
import timestamp from '../../../global/helpers/timestamp';
import pomelloService from '../../../global/services/pomello';
import { appModels } from '../../app';
import Modal from '../../modal';

type NoteModalProps = NoteModalOwnProps;

interface NoteModalOwnProps {
  handleClose: () => void;
  task?: appModels.Task;
}

interface NoteModalState {
  readonly hasNoteInputError: boolean;
  readonly note: string;
  readonly type: string;
}

class NoteModal extends Component<NoteModalProps, NoteModalState> {
  state: NoteModalState = {
    hasNoteInputError: false,
    note: '',
    type: 'general',
  };

  noteInput = createRef<HTMLInputElement>();

  componentDidMount = () => {
    const noteInput = this.noteInput.current;
    if (noteInput) {
      noteInput.focus();
    }
  };

  handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleNoteSave();

    this.props.handleClose();
  };

  handleInputChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.currentTarget;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleNoteSave = () => {
    const { task } = this.props;
    const { note, type } = this.state;

    if (!note.length) {
      return this.setState({ hasNoteInputError: true });
    }

    this.setState({ hasNoteInputError: false });

    if (!task) {
      throw new Error('Unable to associate note to task');
    }

    pomelloService.logEvent({
      type: 'note',
      start_time: timestamp(),
      service_id: task.id,
      parent_service_id: task.idCard,
      meta: { type, note },
    });
  };

  render() {
    const { handleClose } = this.props;
    const { hasNoteInputError, note, type } = this.state;

    return (
      <Modal
        buttons={[
          { label: 'Cancel' },
          { label: 'Save', callback: this.handleNoteSave },
        ]}
        handleClose={handleClose}
      >
        <div className="content">
          <h6 className="heading">Add note</h6>
          <form onSubmit={this.handleFormSubmit}>
            <div className="field">
              <label htmlFor="type" className="label">
                Type
              </label>
              <div className="select">
                <select
                  id="type"
                  name="type"
                  onChange={this.handleInputChange}
                  value={type}
                >
                  <option value="general">General</option>
                  <option value="internal">Internal distraction</option>
                  <option value="external">External distraction</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label htmlFor="note" className="label">
                Note
              </label>
              <input
                className={classnames('input', {
                  'is-danger': hasNoteInputError,
                })}
                id="note"
                name="note"
                onChange={this.handleInputChange}
                ref={this.noteInput}
                type="text"
                value={note}
              />
              {hasNoteInputError && (
                <span className="help is-danger">A note is required</span>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default NoteModal;
