import React, { SFC } from 'react';
import Placeholder from '../../features/placeholder';

const BoardsPlaceholder: SFC = () => (
  <>
    {[150, 200, 180].map(size => (
      <div className="list__item" key={size}>
        <span className="list__content">
          <Placeholder size={size} />
          &nbsp;
        </span>
        <span className="list__actions">
          <button className="icon chevron is-rotated-180" />
        </span>
      </div>
    ))}
  </>
);

export default BoardsPlaceholder;
