import { ThunkAction } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';
import { TimerAction, timerModels } from '.';
import timestamp from '../../global/helpers/timestamp';
import { RootState } from '../../store/types';

const CREATE_TIMER = 'timer/CREATE_TIMER';
const DECREMENT_TIME = 'timer/DECREMENT_TIME';
const DESTROY_TIMER = 'timer/DESTROY_TIMER';
const PAUSE_TIMER = 'timer/PAUSE_TIMER';
const RESUME_TIMER = 'timer/RESUME_TIMER';
const SET_MARKER = 'timer/SET_MARKER';
const START_TIMER = 'timer/START_TIMER';
const UNSET_MARKER = 'timer/UNSET_MARKER';

export const createTimer = createStandardAction(CREATE_TIMER)<
  timerModels.Settings
>();
export const decrementTime = createStandardAction(DECREMENT_TIME)();
export const destroyTimer = createStandardAction(DESTROY_TIMER)();
export const pauseTimer = createStandardAction(PAUSE_TIMER)();
export const resumeTimer = createStandardAction(RESUME_TIMER)();
export const setMarker = createStandardAction(SET_MARKER)<{
  id: string;
  timestamp: number;
}>();
export const startTimer = createStandardAction(START_TIMER)();
export const unsetMarker = createStandardAction(UNSET_MARKER)<string>();

export const addMarker = (
  id: string
): ThunkAction<void, RootState, undefined, TimerAction> => dispatch => {
  dispatch(
    setMarker({
      id,
      timestamp: timestamp(),
    })
  );
};
