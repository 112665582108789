import { isNumber } from 'lodash';
import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';
import timestamp from '../../../helpers/timestamp';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: { duration?: number } = {}
) {
  const task = getVariable('task');
  const taskPauseTime = getVariable('timer.marker.taskPause.timestamp');

  if (!task) {
    throw new Error('Unable to find paused task');
  }

  if (!isNumber(taskPauseTime)) {
    throw new Error('Unable to calculate pause duration');
  }

  if (!meta.duration) {
    meta.duration = timestamp() - taskPauseTime;
  }

  const eventId = `${task.id}-${task.startTime.timestamp}`;
  const event = this.getEvent(eventId);

  if (!event) {
    return this.queueJob(eventId, 'taskTimerResumed', [getVariable, meta]);
  }

  return this.logEvent({
    duration: meta.duration,
    parent_id: event.id,
    parent_service_id: getVariable('task.idCard'),
    service_id: getVariable('task.id'),
    start_time: taskPauseTime,
    type: 'pause',
  });
}
