import React, { ChangeEvent, SFC } from 'react';
import { $Values } from 'utility-types';
import { appModels } from '../../features/app';
import { SelectSetting, SettingName, SettingValue } from './SettingsPage';

interface InputSelect {
  setting: SelectSetting;
  updateSetting: (name: SettingName, value: SettingValue) => void;
  value: $Values<appModels.Settings>;
}

const InputSelect: SFC<InputSelect> = ({ setting, updateSetting, value }) => (
  <>
    <div className="select">
      <select
        id={setting.name}
        name={setting.name}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          updateSetting(setting.name, event.currentTarget.value);
        }}
        value={`${value}`}
      >
        {setting.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
    {setting.suffix && (
      <span className="settings__suffix">{setting.suffix}</span>
    )}
  </>
);

export default InputSelect;
