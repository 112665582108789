import classnames from 'classnames';
import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import wakeLock from '../../../global/helpers/wakeLock';
import { RootAction, RootState } from '../../../store/types';
import Placeholder from '../../placeholder';
import Sheet from '../../sheet';
import { timerActions } from '../../timer';
import './clock.scss';
import Dial from './Dial';

export type ClockProps = ClockDefaultProps &
  ClockOwnProps &
  ClockStateProps &
  ClockDispatchProps;

interface ClockDefaultProps {
  autostart: boolean;
  canPause: boolean;
}

interface ClockOwnProps {
  readonly actions?: Array<{
    id: string;
    icon: string;
    label: string;
    callback: () => void;
  }>;
  readonly eyebrow?: string;
  readonly heading?: string;
  readonly loading: boolean;
  readonly themeColor?: string;
  readonly type: string;
}

interface ClockStateProps {
  readonly timerActive: boolean;
  readonly timerReady: boolean;
  readonly timerType: string;
}

interface ClockDispatchProps {
  readonly startTimer: () => void;
}

export interface ClockState {
  readonly actionsSheetOpen: boolean;
  readonly backgroundX: string;
  readonly backgroundY: string;
}

const mapStateToProps = (state: RootState, props: ClockOwnProps) => ({
  timerActive: state.timer.active,
  timerReady: state.timer.time !== undefined && state.timer.type === props.type,
  timerType: state.timer.type || 'default',
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators({ startTimer: timerActions.startTimer }, dispatch);

export class Clock extends Component<ClockProps, ClockState> {
  static defaultProps: ClockDefaultProps = {
    autostart: false,
    canPause: true,
  };

  state: ClockState = {
    actionsSheetOpen: false,
    backgroundX: '50%',
    backgroundY: '50%',
  };

  metaThemeColor = document.querySelector('meta[name="theme-color"]');

  componentDidMount = () => {
    const { autostart, startTimer, timerReady } = this.props;

    if (autostart && timerReady) {
      startTimer();
    }
  };

  componentDidUpdate = (prevProps: ClockProps) => {
    if (
      this.props.autostart &&
      this.props.timerReady &&
      !prevProps.timerReady
    ) {
      this.props.startTimer();
    } else if (this.props.timerActive && !prevProps.timerActive) {
      this.handleTimerStart();
    }
  };

  componentWillUnmount = () => {
    this.restoreThemeColor();
  };

  handleActionsSheetClose = () => {
    this.setState({ actionsSheetOpen: false });
  };

  handleActionsSheetOpen = () => {
    this.setState({ actionsSheetOpen: true });
  };

  handleStartClick = (event: MouseEvent<HTMLButtonElement>) => {
    wakeLock.enableIfNecessary();

    const { clientX, clientY } = event;

    this.setState({
      backgroundX: clientX !== 0 ? `${clientX}px` : '50%',
      backgroundY: clientY !== 0 ? `${clientY}px` : '50%',
    });
  };

  handleTimerStart = () => {
    const { autostart, themeColor } = this.props;

    if (themeColor) {
      setTimeout(() => this.setThemeColor(themeColor), autostart ? 0 : 300);
    }
  };

  restoreThemeColor = () => {
    if (this.metaThemeColor) {
      const themeColor = this.metaThemeColor.getAttribute('original-content');

      if (themeColor) {
        this.setThemeColor(themeColor);
      }
    }
  };

  setThemeColor = (color: string) => {
    if (this.metaThemeColor) {
      this.metaThemeColor.setAttribute('content', color);
    }
  };

  render() {
    const {
      actions,
      autostart,
      canPause,
      eyebrow,
      heading,
      loading,
      timerActive,
      timerType,
    } = this.props;
    const { actionsSheetOpen, backgroundX, backgroundY } = this.state;

    return (
      <div
        className={classnames('clock__container', { 'is-active': autostart })}
        data-type={timerType}
      >
        <div className="clock__content">
          <div className="clock__header">
            {eyebrow !== undefined && (
              <div className="clock__eyebrow">
                {!loading ? eyebrow : <Placeholder size={120} />}
              </div>
            )}
            {heading !== undefined && (
              <div className="clock__heading">
                {!loading ? heading : <Placeholder size={200} />}
              </div>
            )}
          </div>
          <div className="clock__dial">
            <Dial
              actionsCallback={this.handleActionsSheetOpen}
              canPause={canPause}
              startCallback={this.handleStartClick}
            />
          </div>
        </div>
        {!autostart && (
          <div
            className={classnames('clock__background', {
              'is-active': timerActive,
            })}
            style={{
              top: backgroundY,
              left: backgroundX,
            }}
          />
        )}
        {actions && (
          <Sheet
            isOpen={actionsSheetOpen}
            items={actions}
            handleClose={this.handleActionsSheetClose}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clock);
