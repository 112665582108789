import {
  ICard,
  ICheckItem,
  IChecklist,
} from '@trello-contrib/trello-api-ts/lib';
import { AxiosResponse } from 'axios';
import { decodeHex, encodeHex } from 'base-62.js';
import { sortBy } from 'lodash';
import { TrelloService } from '..';
import { appModels } from '../../../../features/app';

interface CardAndChecklists extends ICard {
  checklists: IChecklist[];
}

type FetchCardsAndChecklistsSuccess = {
  success: true;
  data: {
    tasks: appModels.Tasks;
    listsTasks: appModels.ListTasks;
  };
};

type FetchCardsAndChecklistsFailure = {
  success: false;
};

type TransformedChecklist = {
  checkItems: {
    [key: string]: appModels.TaskCheckItem;
  };
  checklists: appModels.TaskCardChecklist[];
};

const transformCardsToTasks = (cards: CardAndChecklists[]) => {
  const listsTasks: string[] = [];

  const tasks = sortBy(cards, 'pos').reduce(
    (sortedTasks, card) => {
      const task = transformCardToTask(card);

      listsTasks.push(task.shortId);

      if (card.checklists.length) {
        const { checkItems, checklists } = transformChecklistToTasks(card);

        task.checklists = checklists;

        sortedTasks = { ...sortedTasks, ...checkItems };
      }

      sortedTasks[task.shortId] = task;

      return sortedTasks;
    },
    {} as appModels.Tasks
  );

  return { tasks, listsTasks };
};

const transformChecklistToTasks = (card: CardAndChecklists) =>
  sortBy(card.checklists, 'pos').reduce(
    (prev, cur) => {
      const checkItems: string[] = [];

      const incompleteCheckItems = cur.checkItems.filter(
        ({ state }) => state === 'incomplete'
      );

      if (!incompleteCheckItems.length) {
        return prev;
      }

      sortBy(incompleteCheckItems, 'pos').forEach(checkItem => {
        const shortId = encodeHex(checkItem.id);

        checkItems.push(shortId);
        prev.checkItems[shortId] = transformCheckItemToTask(checkItem, card);
      });

      prev.checklists.push({
        checkItems,
        id: cur.id,
        name: cur.name,
      });

      return prev;
    },
    { checkItems: {}, checklists: [] } as TransformedChecklist
  );

const transformCardToTask = ({
  id,
  idBoard,
  idList,
  name,
}: CardAndChecklists): appModels.TaskCard => ({
  checklists: [],
  id,
  idBoard,
  idCard: undefined,
  idList,
  name,
  shortId: encodeHex(id),
  shortIdBoard: encodeHex(idBoard),
  shortIdCard: undefined,
  shortIdList: encodeHex(idList),
});

const transformCheckItemToTask = (
  { id, name }: ICheckItem,
  { id: idCard, idBoard, idList }: CardAndChecklists
): appModels.TaskCheckItem => ({
  checklists: undefined,
  id,
  idBoard,
  idCard,
  idList,
  name,
  shortId: encodeHex(id),
  shortIdBoard: encodeHex(idBoard),
  shortIdCard: encodeHex(idCard),
  shortIdList: encodeHex(idList),
});

export default async function(
  this: TrelloService,
  idList: string
): Promise<FetchCardsAndChecklistsSuccess | FetchCardsAndChecklistsFailure> {
  try {
    const response: AxiosResponse<CardAndChecklists[]> = await this.api.get(
      `/lists/${decodeHex(idList)}/cards`,
      {
        params: {
          checklists: 'all',
        },
      }
    );

    return {
      success: true,
      data: transformCardsToTasks(response.data),
    };
  } catch (error) {
    return { success: false };
  }
}
