import { camelCase } from 'lodash';
import pomelloService, { EventsType } from '../../../global/services/pomello';
import { GetSnapshotVariable } from '../components/Triggers';

export default function(
  action: any,
  getVariable: GetSnapshotVariable,
  meta: any
) {
  const eventName: keyof EventsType =
    action.event.indexOf('timer') !== -1
      ? camelCase(`${getVariable('timer.type')}-${action.event}`)
      : action.event;

  if (pomelloService.hasOwnProperty(eventName)) {
    return pomelloService[eventName](getVariable, meta);
  }
}
