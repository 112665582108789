import { createSelector } from 'reselect';
import { getTask } from '../../global/selectors/getTask';
import { RootState } from '../../store/types';

const getTimestamp = (state: RootState) => {
  const {
    app: { currentTaskStartTime },
    timer: { markers },
  } = state;

  if (currentTaskStartTime) {
    return currentTaskStartTime.timestamp;
  } else if (markers.breakStart) {
    return markers.breakStart.timestamp;
  }

  return null;
};

export const getEventId = createSelector(
  getTask,
  getTimestamp,
  (task, timestamp) => {
    if (task && timestamp) {
      return `${task.id}-${timestamp}`;
    }

    return null;
  }
);
