import { PomelloService } from '..';
import { appModels } from '../../../../features/app';

type FetchTaskSuccess = {
  success: true;
  data: appModels.PomelloTask;
};

type FetchTaskFailure = {
  success: false;
};

export default async function(
  this: PomelloService,
  serviceId: string
): Promise<FetchTaskSuccess | FetchTaskFailure> {
  try {
    const response = await this.api.get(`/tasks/${serviceId}`);

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false };
  }
}
