import React, { SFC } from 'react';
import './placeholder.scss';

export interface PlaceholderProps {
  size: number;
  type?: 'line' | 'icon';
}

const Placeholder: SFC<PlaceholderProps> = ({ size, type = 'line' }) => (
  <span
    className={`placeholder placeholder-${type}`}
    style={{
      width: size,
      height: type === 'icon' ? size : 10,
    }}
  />
);

export default Placeholder;
