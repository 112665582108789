import { sortBy } from 'lodash';
import React, { SFC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import Placeholder from '../../features/placeholder';
import { RootState } from '../../store/types';
import './boardsPage.scss';

export interface BoardsPageProps {
  boards?: appModels.Boards;
}

const mapStateToProps = (state: RootState) => ({
  boards: state.app.boards,
});

export const BoardsPage: SFC<BoardsPageProps> = ({ boards }) => (
  <>
    <AppBar title="Boards" />
    <div className="list">
      {boards
        ? sortBy(boards, 'dateLastView')
            .reverse()
            .map(board => (
              <Link
                to={`/tasks/${board.listIds[0].shortId}`}
                key={board.id}
                className="list__item"
              >
                <span className="list__icon">
                  <span
                    className="boards__icon"
                    style={{
                      backgroundColor: board.backgroundColor,
                      backgroundImage:
                        `url(${board.backgroundImage})` || 'none',
                    }}
                  />
                </span>
                <span className="list__content">{board.name}</span>
              </Link>
            ))
        : [130, 100, 160].map(size => (
            <div key={size} className="list__item">
              <span className="list__icon">
                <Placeholder size={32} type="icon" />
              </span>
              <span className="list__content">
                <Placeholder size={size} />
              </span>
            </div>
          ))}
    </div>
  </>
);

export default connect(mapStateToProps)(BoardsPage);
