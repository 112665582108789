import { isNumber } from 'lodash';
import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  data: any = {}
) {
  const allottedTime = getVariable('timer.allottedTime') || data.allottedTime;
  const taskStartTime = getVariable('task.startTime.time');
  const timerTime = getVariable('timer.time') || 0;

  if (!allottedTime) {
    throw new Error('Unable to get void allotted time');
  }

  if (!isNumber(taskStartTime) || !isNumber(timerTime)) {
    throw new Error('Unable to calculate void duration');
  }

  return this.logEvent({
    type: 'void',
    start_time: getVariable('task.startTime.timestamp'),
    duration: taskStartTime - timerTime,
    service_id: getVariable('task.id'),
    parent_service_id: getVariable('task.idCard'),
    allotted_time: allottedTime,
    remove_prev_task: getVariable('timer') === null,
  });
}
