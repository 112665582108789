import { RootState } from '../../store/types';
import { createSelector } from 'reselect';
import { appModels } from '../../features/app';
import { Assign } from 'utility-types';

export type BoardAndList = Assign<
  appModels.Board,
  {
    lists: appModels.List[];
  }
>;

const getBoards = (state: RootState) => state.app.boards;

const getLists = (state: RootState) => state.app.lists;

export const getBoardsAndLists = createSelector(
  getBoards,
  getLists,
  (boards, lists) => {
    if (!boards || !lists) {
      return null;
    }

    return Object.keys(boards).map(boardId => ({
      ...boards[boardId],
      lists: boards[boardId].listIds.map(({ shortId }) => lists[shortId]),
    }));
  }
);
