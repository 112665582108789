import store from '../../store';

export default function(title: string) {
  const { titleMarker } = store.getState().app.settings;

  const regex = new RegExp(
    `(?:(?:[\\d\\.⅛|¼|⅜|½|⅝|¾|⅞]+)?\\s${titleMarker})?(.+)`
  );

  return regex.test(title) ? RegExp.$1.trim() : title;
}
