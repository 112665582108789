import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';
import shortBreakTimerEnded from './shortBreakTimerEnded';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {}
) {
  return shortBreakTimerEnded.apply(this, [getVariable, meta]);
}
