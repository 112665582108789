import { ThunkAction } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';
import { OvertimeAction, overtimeModels } from '.';
import timestamp from '../../global/helpers/timestamp';
import { RootState } from '../../store/types';
import { appModels } from '../app';

const CREATE_OVERTIME = 'overtime/CREATE_OVERTIME';
const DESTROY_OVERTIME = 'overtime/DESTROY_OVERTIME';
const INCREMENT_TIME = 'overtime/INCREMENT_TIME';

export const createOvertime = createStandardAction(CREATE_OVERTIME)<{
  eventId: overtimeModels.EventId;
  taskId: appModels.Task['id'] | null;
  taskIdCard: appModels.Task['idCard'] | null;
  time: overtimeModels.Time;
  timestamp: overtimeModels.Timestamp;
  type: overtimeModels.Type;
}>();

export const destroyOvertime = createStandardAction(DESTROY_OVERTIME)();

export const incrementTime = createStandardAction(INCREMENT_TIME)();

export const startOvertime = (
  eventId: overtimeModels.EventId,
  task: appModels.Task | null,
  time: overtimeModels.Time,
  type: overtimeModels.Type
): ThunkAction<void, RootState, undefined, OvertimeAction> => dispatch => {
  dispatch({
    type: CREATE_OVERTIME,
    payload: {
      eventId,
      taskId: task ? task.id : null,
      taskIdCard: task && task.idCard ? task.idCard : null,
      time,
      timestamp: timestamp() - time,
      type,
    },
  });
};
