import classnames from 'classnames';
import React, { Component } from 'react';

export interface LoadingButtonProps {
  loading: boolean;
}

export interface LoadingButtonDefaultProps {
  type: string;
  value: string;
}

export interface LoadingButtonState {
  timeout: boolean;
  timeoutId: number | false;
}

class LoadingButton extends Component<
  LoadingButtonProps & LoadingButtonDefaultProps,
  LoadingButtonState
> {
  static defaultProps: Readonly<LoadingButtonDefaultProps> = {
    type: 'submit',
    value: 'Submit',
  };

  state: Readonly<LoadingButtonState> = {
    timeout: true,
    timeoutId: false,
  };

  componentWillReceiveProps(nextProps: LoadingButtonProps) {
    if (nextProps.loading && !this.props.loading) {
      const timeoutId = window.setTimeout(() => {
        this.setState({ timeout: true, timeoutId: false });
      }, 500);

      this.setState({ timeout: false, timeoutId });
    }
  }

  componentWillUnmount() {
    const { timeoutId } = this.state;

    if (timeoutId !== false) {
      window.clearTimeout(timeoutId);
    }
  }

  render() {
    const { loading, type, value } = this.props;
    const { timeout } = this.state;

    if (!(type === 'button' || type === 'submit' || type === 'reset')) {
      throw new Error(`Unrecognized button type "${type}"`);
    }

    return (
      <button
        className={classnames('button', { 'is-loading': loading || !timeout })}
        type={type}
      >
        {value}
      </button>
    );
  }
}

export default LoadingButton;
