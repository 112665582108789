import React, { PureComponent } from 'react';
import { Portal } from 'react-portal';
import './modal.scss';

type Button = {
  callback?: () => void;
  label: string;
  link?: string;
  linkTarget?: string;
};

export interface ModalProps {
  buttons: Button[];
  handleClose: () => void;
}

class Modal extends PureComponent<ModalProps> {
  handleButtonClick = (button: Button) => {
    const { handleClose } = this.props;

    if (button.callback) {
      button.callback();
    }

    handleClose();
  };

  render() {
    const { buttons, children } = this.props;

    return (
      <Portal>
        <div className="modal__overlay">
          <div className="modal">
            <div className="modal__body">{children}</div>
            <div className="modal__buttons">
              {buttons.map(button =>
                button.link ? (
                  <a
                    key={button.label}
                    href={button.link}
                    className="modal__button"
                    target={button.linkTarget}
                  >
                    {button.label}
                  </a>
                ) : (
                  <button
                    key={button.label}
                    className="modal__button"
                    onClick={this.handleButtonClick.bind(this, button)}
                  >
                    {button.label}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default Modal;
