import classnames from 'classnames';
import React, { Component } from 'react';
import storage from '../../../global/helpers/storage';
import pomelloService, { baseURL } from '../../../global/services/pomello';
import LoadingButton from '../../loadingButton';

export interface LoginState {
  emailInput: string;
  passwordInput: string;
  formErrors: {
    [key: string]: string;
  };
  formLoading: boolean;
}

type InputsState = Pick<LoginState, 'emailInput' | 'passwordInput'>;

class Login extends Component<{}, LoginState> {
  state: Readonly<LoginState> = {
    emailInput: '',
    formErrors: {},
    formLoading: false,
    passwordInput: '',
  };

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      formErrors: {},
      formLoading: true,
    });

    await this.authenticateUser();

    this.setState({ formLoading: false });
  };

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({ [`${name}Input`]: value } as InputsState);
  };

  authenticateUser = async () => {
    const { emailInput: email, passwordInput: password } = this.state;

    const response = await pomelloService.authUser(email, password);

    if (response.success) {
      storage.set('pomelloToken', response.data.token);
    } else if (response.data) {
      this.setState({ formErrors: response.data });
    }
  };

  render() {
    const { emailInput, formErrors, formLoading, passwordInput } = this.state;

    return (
      <form onSubmit={this.handleFormSubmit}>
        <div className="field">
          <label htmlFor="email" className="label">
            Email
          </label>
          <input
            className={classnames({ 'is-danger': formErrors.email })}
            id="email"
            name="email"
            onChange={this.handleInputChange}
            placeholder="Email address"
            required={true}
            type="email"
            value={emailInput}
          />
          {formErrors.email && (
            <p className="help is-danger">{formErrors.email}</p>
          )}
        </div>
        <div className="field">
          <label htmlFor="password" className="label">
            Password
          </label>
          <input
            className={classnames({ 'is-danger': formErrors.password })}
            id="password"
            name="password"
            onChange={this.handleInputChange}
            placeholder="Password"
            required={true}
            type="password"
            value={passwordInput}
          />
          {formErrors.password && (
            <p className="help is-danger">{formErrors.password}</p>
          )}
        </div>
        <div className="login__submit-field field">
          <LoadingButton loading={formLoading} value="Log in" />
          <a href={`${baseURL}/password/reset`} target="_blank">
            Forgot password?
          </a>
        </div>
      </form>
    );
  }
}

export default Login;
