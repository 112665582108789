import React, { SFC } from 'react';
import { Portal } from 'react-portal';
import { Transition } from 'react-transition-group';
import './sheet.scss';

export interface SheetProps {
  readonly handleClose: () => void;
  readonly isOpen: boolean;
  readonly items: Array<{
    id: string;
    icon?: string;
    label: string;
    callback: () => void;
  }>;
}

const Sheet: SFC<SheetProps> = ({ handleClose, isOpen, items }) => (
  <Transition in={isOpen} appear={true} unmountOnExit={true} timeout={160}>
    {status => (
      <Portal>
        <div className={`sheet__overlay is-${status}`} onClick={handleClose}>
          <div className={`sheet__component is-${status}`}>
            <ul>
              {items.map(({ callback, icon, id, label }) => (
                <li
                  key={id}
                  className="sheet__item"
                  role="button"
                  onClick={() => {
                    callback();
                    handleClose();
                  }}
                >
                  {icon && (
                    <span className="sheet__icon">
                      <img src={icon} alt={label} />
                    </span>
                  )}
                  <div>{label}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Portal>
    )}
  </Transition>
);

export default Sheet;
