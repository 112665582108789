import { Howl } from 'howler';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { timerActions, timerModels } from '..';
import { RootAction, RootState } from '../../../store/types';
import { triggersEmitter } from '../../triggers';

type Sound = 'startSound' | 'tickSound' | 'endSound';

type SoundSrc = 'startSoundSrc' | 'tickSoundSrc' | 'endSoundSrc';

type SoundVol = 'startSoundVol' | 'tickSoundVol' | 'endSoundVol';

export interface TimerProps {
  active: boolean;
  decrementTime: () => void;
  destroyTimer: () => void;
  endSoundSrc: string | null;
  endSoundVol: number;
  paused: boolean;
  startSoundSrc: string | null;
  startSoundVol: number;
  startTime?: number;
  startTimer: () => void;
  tickSoundSrc: string | null;
  tickSoundVol: number;
  time?: timerModels.Time;
}

const mapStateToProps = (state: RootState) => ({
  active: state.timer.active,
  endSoundSrc: state.timer.endSoundSrc || null,
  endSoundVol: state.timer.endSoundVol || 0,
  paused: state.timer.paused,
  startSoundSrc: state.timer.startSoundSrc || null,
  startSoundVol: state.timer.startSoundVol || 0,
  tickSoundSrc: state.timer.tickSoundSrc || null,
  tickSoundVol: state.timer.tickSoundVol || 0,
  time: state.timer.time,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      decrementTime: timerActions.decrementTime,
      destroyTimer: timerActions.destroyTimer,
      startTimer: timerActions.startTimer,
    },
    dispatch
  );

export class Timer extends Component<TimerProps> {
  startSound: Howl | null = null;

  tickSound: Howl | null = null;

  endSound: Howl | null = null;

  tickTimeoutId?: number;

  timerId?: number;

  componentDidUpdate(prevProps: TimerProps) {
    if (!this.props.active) {
      if (this.props.time && !prevProps.time) {
        this.handleTimerCreate();
      } else if (prevProps.active) {
        this.handleTimerDestroy();
      }
      if (this.props.startSoundSrc !== prevProps.startSoundSrc) {
        this.setSound('start');
      }
      if (this.props.tickSoundSrc !== prevProps.tickSoundSrc) {
        this.setSound('tick');
      }
      if (this.props.endSoundSrc !== prevProps.endSoundSrc) {
        this.setSound('end');
      }
    } else {
      if (!prevProps.active) {
        this.handleTimerStart();
      } else if (this.props.time === 0 && prevProps.time === 1) {
        this.handleTimerEnd();
      } else if (this.props.paused && !prevProps.paused) {
        this.handleTimerPause();
      } else if (!this.props.paused && prevProps.paused) {
        this.handleTimerResume();
      }
    }

    if (this.props.startSoundVol !== prevProps.startSoundVol) {
      this.setVolume('start');
    }
    if (this.props.tickSoundVol !== prevProps.tickSoundVol) {
      this.setVolume('tick');
    }
    if (this.props.endSoundVol !== prevProps.endSoundVol) {
      this.setVolume('end');
    }
  }

  handleTimerCreate = () => {
    this.timerId = new Date().getTime();
  };

  handleTimerDestroy = () => {
    const { tickSound, tickTimeoutId } = this;

    if (tickTimeoutId) {
      clearTimeout(tickTimeoutId);
      delete this.tickTimeoutId;
    }

    if (tickSound) {
      tickSound.stop();
    }
  };

  handleTimerStart = () => {
    triggersEmitter.emit('event', 'timerStarted');

    const { startSound, tickSound } = this;

    if (startSound) {
      startSound
        .once('end', () => {
          if (tickSound) {
            tickSound.play();
          }
        })
        .play();
    } else if (tickSound) {
      tickSound.play();
    }

    this.tickTimer();
  };

  handleTimerEnd = () => {
    triggersEmitter.emit('event', 'timerEnded');

    const { endSound, tickSound } = this;

    if (tickSound) {
      tickSound.stop();
    }

    if (endSound) {
      endSound.play();
    }

    delete this.tickTimeoutId;

    this.props.destroyTimer();
  };

  handleTimerPause = () => {
    triggersEmitter.emit('event', 'timerPaused');

    const { tickSound, tickTimeoutId } = this;

    if (tickTimeoutId) {
      clearTimeout(tickTimeoutId);
      delete this.tickTimeoutId;

      if (tickSound) {
        tickSound.pause();
      }
    }
  };

  handleTimerResume = () => {
    triggersEmitter.emit('event', 'timerResumed');

    const { tickSound } = this;

    this.tickTimer();

    if (tickSound) {
      tickSound.play();
    }
  };

  setSound = (type: 'start' | 'tick' | 'end') => {
    const sound = `${type}Sound` as Sound;

    const src = this.props[`${sound}Src` as SoundSrc];
    const volume = this.props[`${sound}Vol` as SoundVol];

    this[sound] =
      src !== null
        ? new Howl({
            src,
            volume,
            loop: type === 'tick',
          })
        : null;
  };

  setVolume = (type: 'start' | 'tick' | 'end') => {
    const sound = this[`${type}Sound` as Sound];
    const volume = this.props[`${sound}Vol` as SoundVol];

    if (sound !== null) {
      sound.volume(volume);
    }
  };

  tickTimer = () => {
    const interval = 1000;
    let expected = Date.now() + interval;

    // Cache the timer id to compare whenever we call the step function. If we
    // don't, we will tick new timers when the auto-break setting is enabled.
    const stepTimerId = this.timerId;

    const step = () => {
      const drift = Date.now() - expected;
      const newTimeout = Math.max(0, interval - drift);

      expected += interval;

      this.props.decrementTime();

      if (this.timerId === stepTimerId && this.tickTimeoutId) {
        triggersEmitter.emit('event', 'timerTicked');

        this.tickTimeoutId = window.setTimeout(step, newTimeout);
      }
    };

    this.tickTimeoutId = window.setTimeout(step, interval);
  };

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timer);
