import React, { SFC } from 'react';
import Modal from '../../features/modal';
import { baseURL } from '../../global/services/pomello';

interface PremiumModalProps {
  handleClose: () => void;
  logOutCallback: () => void;
}

const PremiumModal: SFC<PremiumModalProps> = ({
  logOutCallback,
  handleClose,
}) => (
  <Modal
    buttons={[
      { label: 'Switch user', callback: logOutCallback },
      {
        label: 'Learn more',
        link: `${baseURL}/pricing`,
        linkTarget: 'premium',
      },
    ]}
    handleClose={handleClose}
  >
    <h6>Go premium!</h6>
    <div className="content">
      <p>You are currently on a free account.</p>
      <p>Upgrade to premium and gain access to the web version.</p>
    </div>
  </Modal>
);

export default PremiumModal;
