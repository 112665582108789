import { get } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../../store/types';
import { TaskDebriefOwnProps } from './TaskDebrief';
import { TaskFinishedOwnProps } from './TaskFinished';
import { TaskPageOwnProps } from './TaskPage';

const getBoards = (state: RootState) => state.app.boards;

const getAllLists = (state: RootState) => state.app.lists;

const getListIds = (
  state: RootState,
  props: TaskDebriefOwnProps | TaskFinishedOwnProps
) => (props.board ? props.board.listIds : undefined);

const getTaskId = (state: RootState, props: TaskPageOwnProps) =>
  props.match.params.idChecklist || props.match.params.idCard;

const getTasks = (state: RootState) => state.app.tasks;

export const getTask = createSelector(
  getTasks,
  getTaskId,
  (tasks, taskId) => get(tasks, taskId)
);

export const getBoard = createSelector(
  getBoards,
  getTask,
  (boards, task) => {
    if (boards && task) {
      return boards[task.shortIdBoard];
    }
  }
);

export const getLists = createSelector(
  getAllLists,
  getListIds,
  (lists, listIds) => {
    if (lists && listIds) {
      return listIds.map(({ shortId }) => lists[shortId]);
    }
  }
);
