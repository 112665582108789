import { PomelloService } from '..';
import { appModels } from '../../../../features/app';

type UpdateSettingsSuccess = {
  success: true;
  data: appModels.Settings;
};

type UpdateSettingsFailure = {
  success: false;
};

export default async function(
  this: PomelloService,
  settings: appModels.Settings
): Promise<UpdateSettingsSuccess | UpdateSettingsFailure> {
  try {
    const response = await this.api.post('/settings', { settings });

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false };
  }
}
