import classnames from 'classnames';
import produce from 'immer';
import { set, unset } from 'lodash';
import React, { ChangeEvent, Component, Fragment, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import storage from '../../global/helpers/storage';
import { RootState } from '../../store/types';
import BoardsPlaceholder from './BoardsPlaceholder';
import defaultPreferences from './defaultPreferences.json';
import { PreferencesModal } from './PreferencesModal';
import { BoardAndList, getBoardsAndLists } from './selectors';

type ServicePreference = {
  global: boolean;
  boardIncludes: { [key: string]: string };
  boardExcludes: { [key: string]: string };
  listIncludes: { [key: string]: string };
  listExcludes: { [key: string]: string };
};

export type ServicePreferences = {
  updateTrelloCardName: ServicePreference;
  logPomelloEvent: ServicePreference;
  archiveTrelloCard: ServicePreference;
};

export type ServicesPageProps = ServicesPageOwnProps & ServicesPageStateProps;

interface ServicesPageOwnProps extends RouteComponentProps {}

interface ServicesPageStateProps {
  readonly boards?: appModels.Boards;
  readonly boardsAndLists: BoardAndList[] | null;
  readonly lists?: appModels.Lists;
}

interface ServicesPageState {
  readonly expandedBoards: {
    [key: string]: true;
  };
  readonly modal: {
    heading: string;
    idBoardOrList: string;
    type: string;
  } | null;
  readonly preferences: ServicePreferences;
}

const mapStateToProps = (state: RootState) => ({
  boards: state.app.boards,
  boardsAndLists: getBoardsAndLists(state),
  lists: state.app.lists,
});

const preferenceLabels: Array<{
  id: keyof ServicePreferences;
  label: string;
}> = [
  { id: 'updateTrelloCardName', label: 'Add marker to card title' },
  { id: 'logPomelloEvent', label: 'Track productivity with Pomello' },
  { id: 'archiveTrelloCard', label: 'Archive after moving' },
];

class ServicesPage extends Component<ServicesPageProps, ServicesPageState> {
  state: ServicesPageState = {
    expandedBoards: {},
    modal: null,
    preferences: defaultPreferences,
  };

  componentWillMount() {
    const userPreferences = storage.get('boardAndListPrefs');

    if (userPreferences) {
      this.setState({ preferences: userPreferences });
    }
  }

  getModalHeading = (idBoard: string, idList: string | null) => {
    const { boards, lists } = this.props;

    if (!boards || !lists) {
      throw new Error('Unable to get modal heading');
    }

    let heading = boards[idBoard].name;

    if (idList) {
      heading += `: ${lists[idList].name}`;
    }

    return heading;
  };

  handleCollapseClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const { name: id } = event.currentTarget;

    this.setState(
      produce<ServicesPageState>(draft => {
        if (!draft.expandedBoards[id]) {
          draft.expandedBoards[id] = true;
        } else {
          delete draft.expandedBoards[id];
        }
      })
    );
  };

  handleModalOpen = (idBoard: string, idList: string | null) => {
    if (!this.props.boardsAndLists) {
      return;
    }

    this.setState({
      modal: {
        heading: this.getModalHeading(idBoard, idList),
        idBoardOrList: idList || idBoard,
        type: idList !== null ? 'list' : 'board',
      },
    });
  };

  handleModalClose = () => {
    this.setState({ modal: null });
  };

  handleGlobalPrefChange = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const preference = currentTarget.name as keyof ServicePreferences;

    this.setState(
      produce<ServicesPageState>(draft => {
        draft.preferences[preference].global = currentTarget.checked;
      }),
      this.updateStoragePreferences
    );
  };

  handlePrefChange = ({ currentTarget }: ChangeEvent<HTMLSelectElement>) => {
    const [type, id, preference] = currentTarget.id.split('-');

    const value = currentTarget.value;
    const prevValue = currentTarget.getAttribute('data-current-value');

    this.setState(
      produce<ServicesPageState>(draft => {
        const draftPreference =
          draft.preferences[preference as keyof ServicePreferences];

        if (value === 'yes') {
          set(draftPreference, `${type}Includes.${id}`, `${type}.shortId`);
        } else if (value === 'no') {
          set(draftPreference, `${type}Excludes.${id}`, `${type}.shortId`);
        }

        if (prevValue === 'yes') {
          unset(draftPreference, `${type}Includes.${id}`);
        } else if (prevValue === 'no') {
          unset(draftPreference, `${type}Excludes.${id}`);
        }
      }),
      this.updateStoragePreferences
    );
  };

  isExpanded = ({ id }: BoardAndList) => this.state.expandedBoards[id] === true;

  updateStoragePreferences = () => {
    storage.set('boardAndListPrefs', this.state.preferences);
  };

  render() {
    const { boardsAndLists } = this.props;
    const { modal, preferences } = this.state;

    return (
      <>
        <AppBar title="Boards and lists" />
        <div className="list__heading">Default preferences</div>
        <ul className="settings">
          {preferenceLabels.map(preference => (
            <li className="settings__item" key={preference.id}>
              <label htmlFor={`global-${preference.id}`}>
                {preference.label}
              </label>
              <input
                checked={preferences[preference.id].global}
                id={`global-${preference.id}`}
                name={preference.id}
                type="checkbox"
                onChange={this.handleGlobalPrefChange}
              />
            </li>
          ))}
        </ul>
        <div className="list__heading">Board and lists preferences</div>
        <div className="list">
          {boardsAndLists ? (
            boardsAndLists.map(board => (
              <Fragment key={board.id}>
                <div
                  className="list__item"
                  onClick={this.handleModalOpen.bind(this, board.shortId, null)}
                  role="button"
                >
                  <span className="list__content">{board.name}</span>
                  <span className="list__actions">
                    <button
                      className={classnames('icon', 'chevron', {
                        'is-rotated-180': !this.isExpanded(board),
                      })}
                      name={board.id}
                      onClick={this.handleCollapseClick}
                    />
                  </span>
                </div>
                {this.isExpanded(board) && (
                  <div className="list__child">
                    <div className="list__child-heading">Lists</div>
                    {board.lists.map(list => (
                      <div
                        className="list__child-item"
                        key={list.id}
                        onClick={this.handleModalOpen.bind(
                          this,
                          board.shortId,
                          list.shortId
                        )}
                        role="button"
                      >
                        <span className="list__content">{list.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            ))
          ) : (
            <BoardsPlaceholder />
          )}
        </div>
        {modal && (
          <PreferencesModal
            {...modal}
            handleClose={this.handleModalClose}
            handlePrefChange={this.handlePrefChange}
            labels={preferenceLabels}
            preferences={preferences}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(ServicesPage);
