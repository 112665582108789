import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './global/styles/global.scss';
import Base from './pages/base';
import ConnectPage from './pages/connect';
import LoginPage from './pages/login';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import UpdateModal from './UpdateModal';

interface RootState {
  readonly updateModalOpen: boolean;
}

class Root extends Component<{}, RootState> {
  state: RootState = {
    updateModalOpen: false,
  };

  componentWillMount() {
    window.addEventListener('message', this.handleWindowMessageReceived);
  }

  handleUpdateAvailable = () => {
    this.showUpdateModal();
  };

  handleWindowMessageReceived = (message: MessageEvent) => {
    switch (message.data) {
      case 'updateAvailable':
        return this.handleUpdateAvailable();
      default:
        break;
    }
  };

  showUpdateModal = () => {
    this.setState({ updateModalOpen: true });
  };

  hideUpdateModal = () => {
    this.setState({ updateModalOpen: false });
  };

  updateServiceWorker = () => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (!registration || !registration.waiting) {
          return;
        }

        registration.waiting.postMessage('skipWaiting');
      });
    }
  };

  render() {
    const { updateModalOpen } = this.state;

    return (
      <Provider store={store}>
        <Router>
          <>
            <Route component={Base} />
            <Route path="/login" component={LoginPage} />
            <Route path="/connect" component={ConnectPage} />
            {updateModalOpen && (
              <UpdateModal
                handleClose={this.hideUpdateModal}
                updateServiceWorker={this.updateServiceWorker}
              />
            )}
          </>
        </Router>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root') as HTMLElement);

registerServiceWorker();
