import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { appModels } from '../../features/app';
import AppBar from '../../features/appBar';
import Clock from '../../features/clock';
import { timerActions, timerModels } from '../../features/timer';
import { triggersEmitter } from '../../features/triggers';
import skipIcon from '../../global/assets/timer-off.svg';
import getTaskName from '../../global/helpers/getTaskName';
import getSoundSrc from '../../global/helpers/sounds';
import storage from '../../global/helpers/storage';
import { RootAction, RootState } from '../../store/types';
import { getCurrentTask } from './selectors';

export type BreakPageProps = BreakPageOwnProps &
  BreakPageStateProps &
  BreakDispatchProps;

interface RouteParams {
  breakType: 'short' | 'long';
}

export interface BreakPageRouteState {
  autoStart?: boolean;
  nextRoute?: string;
}

interface BreakPageOwnProps
  extends RouteComponentProps<RouteParams, {}, BreakPageRouteState> {}

interface BreakPageStateProps {
  readonly autoStartTasks: appModels.Settings['autoStartTasks'];
  readonly bootstrapped: appModels.Bootstrapped;
  readonly currentTask: appModels.Task | null;
  readonly endSoundSrc: string;
  readonly endSoundVol: number;
  readonly startSoundSrc: string;
  readonly startSoundVol: number;
  readonly startTime: number;
  readonly tickSoundSrc: string;
  readonly tickSoundVol: number;
  readonly timerActive: boolean;
  readonly timerTime?: number;
}

interface BreakDispatchProps {
  readonly createTimer: (settings: timerModels.Settings) => void;
  readonly destroyTimer: () => void;
  readonly addTimerMarker: (id: string) => void;
}

type BreakProps = {
  EndSound: 'shortBreakTimerEndSound' | 'longBreakTimerEndSound';
  EndVol: 'shortBreakTimerEndVol' | 'longBreakTimerEndVol';
  StartSound: 'shortBreakTimerStartSound' | 'longBreakTimerStartSound';
  StartVol: 'shortBreakTimerStartVol' | 'longBreakTimerStartVol';
  TickSound: 'shortBreakTimerTickSound' | 'longBreakTimerTickSound';
  TickVol: 'shortBreakTimerTickVol' | 'longBreakTimerTickVol';
  Time: 'shortBreakTime' | 'longBreakTime';
};

const mapStateToProps = (state: RootState, props: BreakPageOwnProps) => {
  const breakType = `${props.match.params.breakType}Break`;
  const breakTime = `${breakType}Time` as BreakProps['Time'];
  const endSound = `${breakType}TimerEndSound` as BreakProps['EndSound'];
  const endVol = `${breakType}TimerEndVol` as BreakProps['EndVol'];
  const startSound = `${breakType}TimerStartSound` as BreakProps['StartSound'];
  const startVol = `${breakType}TimerStartVol` as BreakProps['StartVol'];
  const tickSound = `${breakType}TimerTickSound` as BreakProps['TickSound'];
  const tickVol = `${breakType}TimerTickVol` as BreakProps['TickVol'];

  return {
    autoStartTasks: state.app.settings.autoStartTasks,
    bootstrapped: state.app.bootstrapped,
    currentTask: getCurrentTask(state),
    endSoundSrc: getSoundSrc(state.app.settings[endSound]),
    endSoundVol: state.app.settings[endVol],
    startSoundSrc: getSoundSrc(state.app.settings[startSound]),
    startSoundVol: state.app.settings[startVol],
    startTime: state.app.settings[breakTime],
    tickSoundSrc: getSoundSrc(state.app.settings[tickSound]),
    tickSoundVol: state.app.settings[tickVol],
    timerActive: state.timer.active,
    timerTime: state.timer.time,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      createTimer: timerActions.createTimer,
      destroyTimer: timerActions.destroyTimer,
      addTimerMarker: timerActions.addMarker,
    },
    dispatch
  );

class BreakPage extends Component<BreakPageProps> {
  componentDidMount() {
    if (this.props.bootstrapped) {
      this.createTimer();
    }
  }

  componentDidUpdate(prevProps: BreakPageProps) {
    if (this.props.bootstrapped && !prevProps.bootstrapped) {
      this.createTimer();
    } else if (this.props.timerActive && !prevProps.timerActive) {
      this.handleTimerStart();
    } else if (
      !this.props.timerActive &&
      prevProps.timerActive &&
      prevProps.timerTime === 0
    ) {
      this.handleTimerEnd();
    }
  }

  componentWillUnmount() {
    const { destroyTimer, timerActive } = this.props;

    if (timerActive) {
      triggersEmitter.emit('event', 'timerDestroyed');

      destroyTimer();
    }
  }

  handleBreakSkip = () => {
    this.goToNextRoute();
  };

  handleTimerStart = () => {
    storage.set('breakStartTime', new Date().valueOf());

    this.props.addTimerMarker('breakStart');
  };

  handleTimerEnd = () => {
    this.goToNextRoute();
  };

  createTimer = () => {
    this.props.createTimer({
      endSoundSrc: this.props.endSoundSrc,
      endSoundVol: this.props.endSoundVol,
      startSoundSrc: this.props.startSoundSrc,
      startSoundVol: this.props.startSoundVol,
      startTime: this.props.startTime,
      tickSoundSrc: this.props.tickSoundSrc,
      tickSoundVol: this.props.tickSoundVol,
      type: `${this.props.match.params.breakType}-break`,
    });
  };

  goToNextRoute = () => {
    const {
      autoStartTasks,
      history,
      location: { state: { nextRoute = '/' } = {} },
    } = this.props;

    const state = { autoStart: autoStartTasks };

    history.push(nextRoute, state);
  };

  render() {
    const {
      bootstrapped,
      currentTask,
      location: { state: { autoStart = false } = {} },
      match: {
        params: { breakType },
      },
    } = this.props;

    const heading = currentTask
      ? getTaskName(currentTask.name)
      : 'Next: New task';

    return (
      <>
        <AppBar transparent={true} />
        <Clock
          actions={[
            {
              id: 'skipBreak',
              icon: skipIcon,
              label: 'Skip break',
              callback: this.handleBreakSkip,
            },
          ]}
          autostart={autoStart}
          canPause={false}
          eyebrow="Take a short break"
          heading={heading}
          loading={!bootstrapped}
          themeColor="#2095f2"
          type={`${breakType}-break`}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreakPage);
