import React, { SFC } from 'react';
import { appModels } from '../../features/app';

interface TaskLists {
  handleListClick: (idList: string, shortIdList: string) => void;
  lastUsedListId?: string;
  lists?: appModels.List[];
}

export const TaskLists: SFC<TaskLists> = ({
  handleListClick,
  lastUsedListId,
  lists,
}) =>
  lists ? (
    <div className="list">
      <h6 className="list__heading">Move card to list</h6>
      {lists.map(list => (
        <button
          className="list__item"
          key={list.id}
          onClick={() => handleListClick(list.id, list.shortId)}
        >
          <span className="list__content">{list.name}</span>
          {list.id === lastUsedListId && (
            <span className="list__actions">Last used</span>
          )}
        </button>
      ))}
    </div>
  ) : null;
