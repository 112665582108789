import React from 'react';
import Placeholder from '../../features/placeholder';

const SettingsPlaceholder = () => {
  return (
    <section>
      <div className="list__heading">
        <Placeholder size={50} />
      </div>
      <ul className="settings">
        {[{ width: 120, hint: 400 }, { width: 140 }, { width: 300 }].map(
          ({ width, hint }, index) => (
            <li key={index} className="settings__item">
              <div className="settings__label">
                <Placeholder size={width} />
                {hint && (
                  <p className="settings__hint">
                    <Placeholder size={hint} />
                  </p>
                )}
              </div>
            </li>
          )
        )}
      </ul>
    </section>
  );
};

export default SettingsPlaceholder;
