import React, { MouseEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { appModels } from '..';
import { ReactComponent as CreateIcon } from '../../../global/assets/add-box.svg';
import { ReactComponent as FlashIcon } from '../../../global/assets/flash.svg';
import { ReactComponent as ProfileIcon } from '../../../global/assets/person.svg';
import { ReactComponent as SettingsIcon } from '../../../global/assets/settings.svg';
import Placeholder from '../../placeholder';
import './menu.scss';

export interface MenuProps {
  handleMenuHide: () => void;
  bootstrapped: appModels.Bootstrapped;
  profile?: appModels.PomelloUser;
}

class Menu extends PureComponent<MenuProps> {
  componentDidMount() {
    this.addEscListener();
  }

  componentWillUnmount() {
    this.removeEscListener();
  }

  handleBodyKeyPress = ({ code }: KeyboardEvent) => {
    if (code === 'Escape' || code === 'escape') {
      this.props.handleMenuHide();
    }
  };

  handleMenuItemClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const { handleMenuHide } = this.props;

    handleMenuHide();
  };

  addEscListener = () => {
    document.body.addEventListener('keydown', this.handleBodyKeyPress);
  };

  removeEscListener = () => {
    document.body.removeEventListener('keydown', this.handleBodyKeyPress);
  };

  render() {
    const { handleMenuHide, bootstrapped, profile } = this.props;

    return (
      <div className="menu">
        <div className="menu__panel">
          <div className="menu__profile">
            <h6>
              {bootstrapped && profile ? (
                profile.name
              ) : (
                <Placeholder size={80} />
              )}
            </h6>
            <span className="menu__email">
              {bootstrapped && profile ? (
                profile.email
              ) : (
                <Placeholder size={140} />
              )}
            </span>
          </div>
          <ul>
            <li>
              <Link
                to="/"
                className="menu__item"
                onClick={this.handleMenuItemClick}
              >
                <span className="menu__icon">
                  <ProfileIcon fill="#444" />
                </span>
                <span>Profile</span>
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="menu__item"
                onClick={this.handleMenuItemClick}
              >
                <span className="menu__icon">
                  <CreateIcon fill="#444" />
                </span>
                <span>Create task</span>
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="menu__item"
                onClick={this.handleMenuItemClick}
              >
                <span className="menu__icon">
                  <FlashIcon fill="#444" />
                </span>
                <span>Boards and lists</span>
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className="menu__item"
                onClick={this.handleMenuItemClick}
              >
                <span className="menu__icon">
                  <SettingsIcon fill="#444" />
                </span>
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="menu__overlay" onClick={handleMenuHide} />
      </div>
    );
  }
}

export default Menu;
