import axios, { AxiosInstance } from 'axios';
import EventEmitter from 'events';
import { GetSnapshotVariable } from '../../../features/triggers/components/Triggers';
import storage from '../../helpers/storage';
import * as events from './events';
import * as handlers from './handlers';

export type EventsType = typeof events;

type HandlersType = typeof handlers;

type EventsAndHandlers<T> = { [K in keyof T]: T[K] };

interface PomelloEvent {
  children: PomelloEvent[];
  eventId: string;
  id: string;
  meta: any;
  parentId: string | null;
  parentServiceId: string | null;
  serviceId: string;
  startTime: string;
  type: string;
}

type JobsQueue = {
  [key: string]: Array<{
    args: [GetSnapshotVariable, any];
    handler: string;
  }>;
};

export interface PomelloService
  extends EventsAndHandlers<HandlersType & EventsType> {
  api: AxiosInstance;
}

export class PomelloService extends EventEmitter {
  static baseURL = process.env.REACT_APP_POMELLO_URL;

  events: PomelloEvent[] = [];

  jobsQueue: JobsQueue = {};

  constructor() {
    super();

    this.setUpApi();

    if (storage.has('pomelloToken')) {
      const token = storage.get('pomelloToken');
      this.storeApiToken(token);
    }

    storage.on('change', (key: string, value: any, prevValue: any) => {
      if (key === 'pomelloToken' && value && !prevValue) {
        this.storeApiToken(value);
      }
    });

    (Object.keys(handlers) as Array<keyof HandlersType>).forEach(handler => {
      this[handler] = handlers[handler];
    });

    (Object.keys(events) as Array<keyof EventsType>).forEach(event => {
      this[event] = events[event];
    });
  }

  setUpApi() {
    this.api = axios.create({
      baseURL: `${PomelloService.baseURL}/api`,
      headers: {
        'Content-Type': 'application/json',
      },
      transformResponse: data => {
        const jsonData = JSON.parse(data);
        return jsonData.hasOwnProperty('data') ? jsonData.data : jsonData;
      },
    });

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          const { status } = error.response;

          if (status === 401) {
            this.emit('error', 'auth');
          } else if (status === 500) {
            this.emit('error', 'server');
          }
        } else if (error.request) {
          this.emit('error', 'network');
        }

        return Promise.reject(error);
      }
    );
  }

  storeApiToken(token: string) {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  getEvent(eventId: string) {
    return this.events.find(event => event.eventId === eventId);
  }

  storeEvent(event: PomelloEvent) {
    const timestamp = new Date(event.startTime).getTime() / 1000;
    const eventId = `${event.serviceId}-${timestamp}`;

    this.events.splice(0, 0, { ...event, eventId });

    if (this.events.length > 5) {
      this.events.pop();
    }

    this.processQueue(eventId);
  }

  processQueue(id: string) {
    const queue = this.jobsQueue[id];

    if (queue) {
      queue.forEach(({ handler, args }) => {
        this[handler as keyof EventsType](...args);
      });

      delete this.jobsQueue[id];
    }
  }

  queueJob(id: string, handler: string, args: [GetSnapshotVariable, any]) {
    const queue = this.jobsQueue[id] || [];

    queue.push({ args, handler });

    this.jobsQueue[id] = queue;
  }
}

const pomelloService = new PomelloService();

export const baseURL = PomelloService.baseURL;

export default pomelloService;
