import { isNumber } from 'lodash';
import { PomelloService } from '..';
import { GetSnapshotVariable } from '../../../../features/triggers/components/Triggers';

export default function(
  this: PomelloService,
  getVariable: GetSnapshotVariable,
  meta: any = {},
  type: string = 'short'
) {
  const task = getVariable('task');

  if (task === null) {
    return; // If we don't have a task, then don't do anything
  }

  const breakStartTime = getVariable('timer.marker.breakStart.time');
  const timerTime = getVariable('timer.time');

  if (!isNumber(breakStartTime) || !isNumber(timerTime)) {
    throw new Error('Unable to calculate break duration');
  }

  return this.logEvent({
    type: 'break',
    start_time: getVariable('timer.marker.breakStart.timestamp'),
    duration: breakStartTime - timerTime,
    service_id: getVariable('task.id'),
    parent_service_id: getVariable('task.idCard'),
    allotted_time: getVariable('timer.allottedTime'),
    meta: { type },
  });
}
