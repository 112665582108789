import { ThunkAction } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';
import { $Values } from 'utility-types';
import { AppAction, appModels } from '.';
import timestamp from '../../global/helpers/timestamp';
import { RootState } from '../../store/types';
import { timerModels } from '../timer';

const HIDE_MENU = 'app/HIDE_MENU';
const INCREMENT_POMODOROS = 'app/INCREMENT_POMODOROS';
const MOVE_TASK_TO_LIST = 'app/MOVE_TASK_TO_LIST';
const REMOVE_POMELLO_USER = 'app/REMOVE_POMELLO_USER';
const REMOVE_TASK_CHECK_ITEM = 'app/REMOVE_TASK_CHECK_ITEM';
const REMOVE_TRELLO_USER = 'app/REMOVE_TRELLO_USER';
const RESET_POMODOROS = 'app/RESET_POMODOROS';
const RESET_SETTINGS = 'app/RESET_SETTINGS';
const SET_CURRENT_LIST = 'app/SET_CURRENT_LIST';
const SET_CURRENT_TASK = 'app/SET_CURRENT_TASK';
const SET_GRACE_PERIOD = 'app/SET_GRACE_PERIOD';
const SET_SETTING = 'app/SET_SETTING';
const SET_STATUS = 'app/SET_STATUS';
const SHOW_MENU = 'app/SHOW_MENU';
const STORE_BOARDS = 'app/STORE_BOARDS';
const STORE_LIST_TASKS = 'app/STORE_LIST_TASKS';
const STORE_LISTS = 'app/STORE_LISTS';
const STORE_POMELLO_USER = 'app/STORE_POMELLO_USER';
const STORE_SETTINGS = 'app/STORE_SETTINGS';
const STORE_TASKS = 'app/STORE_TASKS';
const STORE_TRELLO_USER = 'app/STORE_TRELLO_USER';
const UNSET_CURRENT_LIST = 'app/UNSET_CURRENT_LIST';
const UNSET_CURRENT_TASK = 'app/UNSET_CURRENT_TASK';
const UNSET_GRACE_PERIOD = 'app/UNSET_GRACE_PERIOD';

export const hideMenu = createStandardAction(HIDE_MENU)();

export const incrementPomodoros = createStandardAction(INCREMENT_POMODOROS)();

export const moveTaskToList = createStandardAction(MOVE_TASK_TO_LIST)<{
  shortIdTask: appModels.Task['shortId'];
  shortIdList: appModels.List['shortId'];
}>();

export const removePomelloUser = createStandardAction(REMOVE_POMELLO_USER)();

export const removeTaskCheckItem = createStandardAction(REMOVE_TASK_CHECK_ITEM)<
  appModels.Task['shortId']
>();

export const removeTrelloUser = createStandardAction(REMOVE_TRELLO_USER)();

export const resetPomodoros = createStandardAction(RESET_POMODOROS)();

export const resetSettings = createStandardAction(RESET_SETTINGS)();

export const setCurrentList = createStandardAction(SET_CURRENT_LIST)<
  appModels.List['shortId']
>();

export const setCurrentTask = createStandardAction(SET_CURRENT_TASK)<{
  id: appModels.Task['id'];
  startTime: {
    time: timerModels.Time;
    timestamp: number;
  };
}>();

export const setGracePeriod = createStandardAction(SET_GRACE_PERIOD)<{
  time: timerModels.Time;
  timestamp: number;
}>();

export const setSetting = createStandardAction(SET_SETTING)<{
  name: keyof appModels.Settings;
  value: $Values<appModels.Settings>;
}>();

export const setStatus = createStandardAction(SET_STATUS)<appModels.Status>();

export const showMenu = createStandardAction(SHOW_MENU)();

export const storeBoards = createStandardAction(STORE_BOARDS)<
  appModels.Boards
>();

export const storeListTasks = createStandardAction(STORE_LIST_TASKS)<
  appModels.ListTasksPayload
>();

export const storeLists = createStandardAction(STORE_LISTS)<appModels.Lists>();

export const storePomelloUser = createStandardAction(STORE_POMELLO_USER)<
  appModels.PomelloUser
>();

export const storeSettings = createStandardAction(STORE_SETTINGS)<
  appModels.Settings
>();

export const storeTasks = createStandardAction(STORE_TASKS)<appModels.Tasks>();

export const storeTrelloUser = createStandardAction(STORE_TRELLO_USER)<
  appModels.TrelloUser
>();

export const unsetCurrentList = createStandardAction(UNSET_CURRENT_LIST)();

export const unsetCurrentTask = createStandardAction(UNSET_CURRENT_TASK)();

export const unsetGracePeriod = createStandardAction(UNSET_GRACE_PERIOD)();

let gracePeriodTimeout: number | undefined;
export const startGracePeriod = (): ThunkAction<
  void,
  RootState,
  undefined,
  AppAction
> => (dispatch, getState) => {
  const {
    app: {
      settings: { betweenTasksGracePeriod: gracePeriod },
    },
    timer: { time },
  } = getState();

  if (!time) {
    return;
  }

  // Clear any preexisting timeouts, otherwise the newly set timeout could
  // get cancelled by the previously set one.
  window.clearTimeout(gracePeriodTimeout);

  dispatch({
    type: SET_GRACE_PERIOD,
    payload: {
      time,
      timestamp: timestamp(),
    },
  });

  gracePeriodTimeout = window.setTimeout(() => {
    dispatch({ type: UNSET_GRACE_PERIOD });
  }, gracePeriod * 1000);
};
